import React, { useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  Text,
  TrashCanIcon
} from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import DesktopIcon from '../../svg/desktop.svg';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  AUTOMATION,
  AUTOMATION_HEADERS,
  BUTTONS,
  CONTACT_ADMIN,
  DEFAULT_API_STATUS,
  DELETE,
  DONE,
  ERROR,
  LOADING,
  REFRESH_SESSION,
  SETTINGS,
  SETTINGS_PAGES,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACCESS,
  ADMINISTRATORS,
  ANALYSTS
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { KBListWrapper } from '../kb/KBListWrapper';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import useCurrentPage from '../kb/useCurrentPage';
import { useTranslation } from 'react-i18next';
import { appState } from '../AppState';

export const transformModalContent = (staticText: any, ...tags: string[]) => {
  return (
    <div>
      {staticText[0]}
      <span style={{ fontWeight: 'bold' }}>{tags[0]}</span>
      {staticText[1]}
    </div>
  );
};

export const AutomationTable = () => {

  const currentState = appState();
  const { t } = useTranslation();
  const api = new platformService();
  const [showDialog, setShowDialog] = useState(false);
  const [data, setData] = useState<RowsData[]>([]);
  const [recordToDelete, setRecordToDelete] = useState<Partial<AutomationData>>(
    { Id: 0, Title: '' }
  );
  const [apiStatus, setApiStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const { currentPage, setCurrentPage, onDeleteRowSetCurrentPage } = useCurrentPage();
  const [searchText, setSearchText] = useState<string>("");

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: AUTOMATION.GETTING_ALL_AUTOMATION });

    try {
      const { data: { value } } = await api.getAutomationList('?$select=Id,Title,Status,Description,Enabled&$orderby=CreatedDate desc');

      const rowData: RowsData[] = value.map((automation: AutomationData) => ({
        id: automation['Id'],
        [t('automation.headers.title')]: automation['Title'],
        [t('automation.headers.description')]: automation['Description'],
        [t('automation.headers.status')]: t(`automation.status.${automation['Status'].toLowerCase()}`),
        [t('automation.headers.action')]: (
          <>
            <CheckLicense disable>
              <Checkbox
                toggle
                checked={automation['Enabled']}
                onClick={e => {
                  e.stopPropagation();
                  toggleAutomation(automation['Id'], automation['Enabled']);
                }}
              />
              <Button
                icon={<TrashCanIcon />}
                text
                iconOnly
                title={toTitleCase(DELETE)}
                onClick={e => {
                  e.stopPropagation();
                  setRecordToDelete({
                    Id: automation['Id'],
                    Title: automation['Title']
                  });
                  setShowDialog(true);
                }}
              />
            </CheckLicense>
          </>
        )
      }));

      setData(rowData);
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }
  }, []);

  const loadPage = () => {
    const hasAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS);
    if (!hasAccess) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 })
    }
    else {
      fetchData();
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: toTitleCase(AUTOMATION.AUTOMATION),
        link: ''
      }
    ]
  };

  const toggleAutomation = async (id: number, currentToggleStatus: boolean) => {
    let status = 0;
    setApiStatus({
      status: LOADING,
      msg: AUTOMATION.TOGGLING_AUTOMATION,
      errCode: 0
    });
    try {
      ({ status } = await api.toggleAutomation(id, {
        Enabled: !currentToggleStatus
      }));
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }

    if (status === 204) fetchData();
  };

  const deleteAutomation = async () => {
    let status = 0;
    setShowDialog(false);
    setApiStatus({
      status: LOADING,
      msg: AUTOMATION.DELETING_AUTOMATION,
      errCode: 0
    });
    try {
      ({ status } = await api.deleteAutomation(recordToDelete['Id']));
      setApiStatus({ ...apiStatus, status: DONE });

      onDeleteRowSetCurrentPage(data.length)
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setShowDialog(false);
    }

    if (status === 204) fetchData();
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const onSearchKeyChanged = (data: string) => {
    console.log(data);
    setSearchText(data);
  }

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('automation.title-main')}</title>
      </Helmet>
      <div className="hidden md:block">
        {apiStatus.status === LOADING && (
          <LoadingScreen message={apiStatus.msg} />
        )}
        {apiStatus.status === ERROR && (
          <>
            <Communication {...errConfig} />
            {apiStatus.errCode === 401 && (
              <Button
                content={REFRESH_SESSION}
                primary
                onClick={e => {
                  Providers.globalProvider.login();
                }}
              />
            )}
          </>
        )}
        {apiStatus.status !== ERROR && apiStatus.status !== LOADING && (
          <>
            <KBListWrapper
              title={toTitleCase(AUTOMATION.AUTOMATION)}
              routeName={SETTINGS_PAGES.AUTOMATION}
              headers={AUTOMATION_HEADERS}
              rows={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              breadcrumbs={navs}
              ifEmpty={{
                header: t('automation.there-are-no-automations'),
                subHeader:
                  t('automation.get-started-by-clicking')
              }}
              height={'calc(100vh - 120px)'}
              onSearchKeyChanged={onSearchKeyChanged}
              searchText={searchText}
            />
            <Dialog
              open={showDialog}
              onCancel={() => setShowDialog(false)}
              cancelButton={toTitleCase(BUTTONS.CANCEL)}
              confirmButton={toTitleCase(DELETE)}
              onConfirm={() => {
                deleteAutomation();
              }}
              content={transformModalContent`${t('automation.are-you-sure-you-want-to-delete', { recordToDeleteTitle: recordToDelete['Title'] })}`}
              header={`${t('automation.delete-from-Automation', { recordToDeleteTitle: recordToDelete['Title'] })}`}
              style={{ maxWidth: '500px' }}
            />
          </>
        )}
      </div>
      <MobileSettingsView />
    </CheckLogin>
  );
};

export const MobileSettingsView = () => {
  const { t } = useTranslation();
  return (
    <div className="md:hidden h-screen flex flex-col justify-center items-center">
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center text-center mx-5 pb-10">
          <DesktopIcon width="100" height="100" />
          <Text
            content={t('automation.this-page-is-not-available')}
            weight="bold"
          />
        </div>
      </div>
    </div>
  );
};
