import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  MenuIcon,
  Tooltip
} from '@fluentui/react-northstar';
import { Icon } from '@fluentui/react/lib/Icon';
import AppCSS from './../../App.module.css';
import logo from './../../../images/tikit-logo.png';

import { Login } from '@microsoft/mgt-react';
import { appState, AppStateContext } from '../../AppState';
import { Link } from 'react-router-dom';
import {
  ADMINISTRATORS,
  ANALYSTS,
  AGENTS,
  KNOWLEDGEAGENT
} from '../../shared/utils/constants';
import { TicketBoardMobileMenu } from '../../shared/components/TicketBoardMobileMenu';
import { i18n } from '../../shared/utils/helper';


const { t } = i18n();

interface Props {
  active?: number;
}

export const Nav = (props: Props) => {
  
  const appContextState = appState();

  let [currentUser, setCurrentUser] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    (function retryNotExist() {
      if (!document.querySelector('mgt-login')) {
        return setTimeout(retryNotExist);
      }

      let mgtLoginSelector = document.querySelector('mgt-login').shadowRoot;
      if (!mgtLoginSelector.querySelector('mgt-person')) {
        return setTimeout(retryNotExist);
      }

      let mgtPerson = mgtLoginSelector.querySelector('mgt-person').shadowRoot;
      if (mgtPerson.querySelector('.line1')) {
        mgtPerson
          .querySelector('.line1')
          .setAttribute('style', 'display: none');
      }

      let mgtflyout = mgtLoginSelector.querySelector('mgt-flyout');

      mgtflyout
        .querySelector('.popup-command')
        .setAttribute('style', 'color:var(--teams-font-color)');

      if (mgtPerson.querySelector('.person-root img') !== null) {
        mgtPerson
          .querySelector('.person-root img')
          .setAttribute('style', 'cursor: pointer');
      }

      mgtLoginSelector
        .querySelector('.login-button')
        .setAttribute(
          'style',
          '--background-color--hover: rgba(255,255,255,0.3);'
        );
    })();
  }, [currentUser]);

  const goToHelpTikit = () => {
    window.open('https://help.tikit.ai/', '_blank');
  };

  return (
    <AppStateContext.Consumer>
      {state => {
        return (
          <>
            <div className={`block md:hidden`}>
              {isMenuOpen && (
                <TicketBoardMobileMenu
                  active={props.active}
                  menuClose={() => {
                    setIsMenuOpen(false);
                  }}
                />
              )}
            </div>
            <Flex
              space="between"
              className={`items-center ${state.theme === 'teamsDarkTheme' ? 'border-b': ''}`}
              style={{backgroundColor: '#222', borderColor: 'rgba(255,255,255,0.3)'}}
            >
              <Flex.Item>
                <Flex>
                  {(appContextState.userRoles.roles.includes(ADMINISTRATORS )|| appContextState.userRoles.roles.includes(ANALYSTS) ||
                      appContextState.userRoles.roles.includes(AGENTS) || appContextState.userRoles.roles.includes(KNOWLEDGEAGENT) ) && (
                    <Flex.Item>
                      <div
                        className={`action-button flex items-center pl-3`}
                      >
                        <Button
                          primary
                          text
                          icon={<MenuIcon size="large" />}
                          title="Edit"
                          size="medium"
                          iconOnly
                          onClick={() => {
                            setIsMenuOpen(true);
                          }}
                          className={`${AppCSS.navActionButton} mr-1 ml-1 lg-hidden-important`}
                          style={{color: 'white'}}
                        />
                      </div>
                    </Flex.Item>
                  )}
                  <Flex.Item>
                    <div
                      className={`action-button flex items-center pl-3 mt-1`}
                    >
                      <img src={logo} style={{ height: '20px' }} />
                    </div>
                  </Flex.Item>
                </Flex>
              </Flex.Item>

              <Flex.Item>
                <Flex gap="gap.medium" vAlign="center">
                    {(appContextState.userRoles.roles.includes(ADMINISTRATORS )|| appContextState.userRoles.roles.includes(ANALYSTS) ||
                      appContextState.userRoles.roles.includes(AGENTS) || appContextState.userRoles.roles.includes(KNOWLEDGEAGENT) )  && (
                      <Flex gap="gap.large">
                        <Tooltip
                          content={t("common.settings")}
                          trigger={
                            <Link to="/settings">
                              <Icon
                                iconName="Settings"
                                className={`text-lg text-white cursor-pointer`}
                              />
                            </Link>
                          }
                        />
                      
                      <Tooltip
                        content={t("common.help-center")}
                        trigger={
                          <Icon
                            iconName="Help"
                            className={`text-lg text-white cursor-pointer`}
                            onClick={goToHelpTikit}
                          />
                        }
                      />
                      
                      </Flex>
                    )}
                  <div className={`action-button cursor-pointer`}>
                    <Login className={`${state.theme === 'teamsDarkTheme' ? 'login-popup-dark': ''}`}/>
                    {state.userImages && setCurrentUser(state.currentUserEmail)}
                  </div>
                </Flex>
              </Flex.Item>
            </Flex>
          </>
        );
      }}
    </AppStateContext.Consumer>
  );
};
