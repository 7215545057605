import { Button, ButtonProps, CloseIcon, ComponentEventHandler, Dialog, Flex } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { appState } from "../AppState";
import { LoadingScreen } from "../shared/components/LoadingScreen";
import { platformService } from "../shared/services/platform.service";
import { FILTERS_STRINGS, STATUS_GUID } from "../shared/utils/constants";
import { TemplateFormStateProps } from "./TemplateFieldsForm";
import { TicketTemplatesSelection } from "./TicketTemplatesSelection";
import { TicketTemplatesSelectionComplete } from "./TicketTemplatesSelectionComplete";
import { TicketTemplatesSelectionView } from "./TicketTemplatesSelectionView";
import { useTranslation } from 'react-i18next';


const api = new platformService();


export interface TemplateListDialogProp {
  isNewTicket: boolean;
  formState: any;
  showTemplate: boolean; isApplyingTemplateWithLifecycle: boolean;
  templateList: any[];
  dispatch: React.Dispatch<any>;
  closeApplyTemplate: VoidFunction;
  applyTemplate: VoidFunction;
  ticketApprovals: TicketApprovals[];
}

export const sendRequestToApplyLifecycle = async function (selectedLifecycleId: number, ticketId: number) {
  try {
    let result = await api.applyLifecycle(ticketId, selectedLifecycleId);
    return (parseInt(result.data.data));
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export const TemplateListDialog: React.FC<TemplateListDialogProp> = props => {
  let { isNewTicket, formState, showTemplate, isApplyingTemplateWithLifecycle, templateList, dispatch, closeApplyTemplate, applyTemplate, ticketApprovals } = props;

  const { t } = useTranslation();

  const currentState = appState();
  const [templatePage, setTemplatePage] = useState(0);

  const templatePages = ['select', 'done'];

  useEffect(() => {
    if(showTemplate)
      setTemplatePage(0);
  }, [showTemplate]);

  const getTemplatePageCountDialog = () => {
    if (formState.SelectedTemplate.NoCustomForm) { //this is when custom form is not used
      formState.SelectedTemplate.ClearFormAnswer = false;
      return 1;
    }
    else //This will use the custom form
      return 2;
  }

  const getTemplatePage = () => {
    switch (templatePages[templatePage]) {
      case 'select':
        return (
          <TicketTemplatesSelection
            formData={
              {
                templates: templateList,
                ...currentState
              } as TemplateFormStateProps
            }
            ticketData={formState}
            dispatchEvent={dispatch}
          />
        );
      case 'view':
        return (
          <TicketTemplatesSelectionView
            formData={
              {
                templates: templateList,
                ...currentState
              } as TemplateFormStateProps
            }
            ticketData={formState}
          />
        );
      case 'done':
        return (
          <TicketTemplatesSelectionComplete
            formData={
              {
                templates: templateList,
                ...currentState
              } as TemplateFormStateProps
            }
            ticketData={formState}
            dispatchEvent={dispatch}
          />
        );
      default:
        return <></>;
    }
  };


  const templatePreviousAction = () => {
    if (templatePage >= 1) setTemplatePage(templatePage - 1);
    else closeApplyTemplate();
  };
  const templateNextAction = async () => {
    dispatch({ type: "IsTemplateSelectionSaving", data: true });
    if (formState.SelectedTemplate.Lifecycle) {
      if ([STATUS_GUID.RESOLVED, STATUS_GUID.CLOSED].indexOf((formState.Status?.Guid ?? "")) >= 0) {
        dispatch({ type: "TemplateHasError", data: true });
        dispatch({ type: "TemplateErrorMessage", data: t('ticket-details.apply-template.lifecycle-cannot-applied-resolved-closed') });
        return;
      }
      if ((formState.Id && formState.Id > 0) && (ticketApprovals.length || currentState.hasActiveTasks)) {
        dispatch({ type: "TemplateHasError", data: true });
        dispatch({ type: "TemplateErrorMessage", data: t('ticket-details.apply-template.lifecycle-cannot-applied-existing-approvals-tasks') });
        return;
      }
    }

    if (templatePage + 1 < getTemplatePageCountDialog())
      setTemplatePage(templatePage + 1);
    else {
      applyTemplate();
      setTemplatePage(0);
    }
  };


  return (<Dialog styles={{
    [`@media screen and (max-width: 500px)`]: {
      width: 'fit-content',
    }
  }}
    content={(!isApplyingTemplateWithLifecycle)
      ? getTemplatePage()
      : <Flex
        column
        style={{ flex: 1, maxHeight: '40vh' }}
        gap="gap.large"
      >
        <LoadingScreen message={t('ticket-details.apply-template.dialog.applying-lifecycle-to-ticket')} />
      </Flex>
    }
    open={showTemplate}
    headerAction={(!isApplyingTemplateWithLifecycle) ? {
      icon: <CloseIcon />,
      title: t('ticket-details.apply-template.dialog.button.close'),
      onClick: closeApplyTemplate
    } : {}}
    footer={(!isApplyingTemplateWithLifecycle) ?
      <Flex gap="gap.small">
        <Flex.Item push>
          <Button
            content={templatePage === 0 ? t('ticket-details.apply-template.dialog.button.cancel') : t('ticket-details.apply-template.dialog.button.back')}
            onClick={templatePreviousAction}
          />
        </Flex.Item>
        <Flex.Item>
          <Button
            content={
              templatePage + 1 < getTemplatePageCountDialog()
                ? t('ticket-details.apply-template.dialog.button.next')
                : formState &&
                  (formState.SelectedTemplate.ClearFormAnswer == false || isNewTicket)
                  ? t('ticket-details.apply-template.dialog.button.save')
                  : t('ticket-details.apply-template.dialog.button.save-send')
            }
            primary
            onClick={templateNextAction}
            disabled={
              templatePages[templatePage] == 'select'
                ? !(formState && formState.SelectedTemplate.Id)
                : false
            }
          />
        </Flex.Item>
      </Flex>

      : <></>
    }
  />);

}