import React from 'react';
import Styles from './SlaPills.module.css';

import {
  Flex,
  Text,
  ProviderConsumer as FluentUIThemeConsumer,
  Skeleton
} from '@fluentui/react-northstar';
import BreachingInSVG from '../../../../svg/slas.svg';
import {
  SLAActionType,
  SlaStateStrings,
  TicketSlaState,
  TICKET_DETAIL
} from '../../utils/constants';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const Title = ({ text }) => (
  <Text size="small" style={{ color: '#8f90a6', marginLeft: '4px' }}>
    {text}:
  </Text>
);

type Props = {
  ticketSlas: TicketSla[];
  isLoading?: boolean;
  svgGap: boolean;
  viewType?: 'Board' | 'Edit' | 'List';
};

const SkeletonItemStyle = {
  width: '24px',
  height: '14px',
  style: {
    borderRadius: '7px'
  }
};

const PillsColors = {
  [TicketSlaState.Active]: {
    backgroundColor: '#e3fff1',
    color: '#05a660'
  },
  [TicketSlaState.Breached]: {
    backgroundColor: '#ffe6e6',
    color: '#e53535'
  },
  [TicketSlaState.ApproachBreached]: {
    backgroundColor: '#fff8e6',
    color: '#e57a00'
  },
  [TicketSlaState.Satisfied]: {
    backgroundColor: '#f2f2f5',
    color: '#8f90a6'
  }
};

export const SlaPills = ({
  isLoading = false,
  ticketSlas = [],
  svgGap = true,
  viewType = 'Edit'
}: Props) => {

  const pillsToShow = viewType === 'List' ? 1 : 3;

  const durationCalculator = (
    currentDateAndTime: DateTime,
    assignDateAndTime: DateTime,
    slaCriteria: SlaCriterias
  ) => {
    const _assignDateAndTimePlusDuration = assignDateAndTime.plus({
      [slaCriteria.TimeUnitPart.toLowerCase()]: slaCriteria.TimeDigitPart
    });
    const { days, hours, minutes } = _assignDateAndTimePlusDuration
      .diff(currentDateAndTime, ['days', 'hours', 'minutes'])
      .toObject();
      if (days > 0) {
          return `${days}d`;
      } else if (hours > 0) {
          return `${hours}h`;
      } else if (minutes > 0) {
          return `${Math.round(minutes)}m`;
      } else if (minutes < 0) {
          return '0m';
      }
  };

  const calculateSlaBreaching = (_ticketSlas: TicketSla[]) => {
    const currentDateAndTime = DateTime.now();
    const _slaData: SlaCalculate[] = [];
    _ticketSlas?.forEach(
      ({ ServiceLevelAgreement, SlaState, CreatedDate, ModifiedDate }) => {
        let _sla: SlaCalculate = {
          name: ServiceLevelAgreement.Name,
          style: {
            color: '',
            backgroundColor: ''
          }
        };

        const _approachBreach = ServiceLevelAgreement.SlaCriterias.find(
          criteria => criteria.SlaActionTypeId === SLAActionType.ApproachBreach
        );
        const _breach = ServiceLevelAgreement.SlaCriterias.find(
          criteria => criteria.SlaActionTypeId === SLAActionType.Breached
        );
        const _timeStamp = DateTime.fromJSDate(new Date(ModifiedDate)).toFormat(
          'LL/dd/yy hh:mm a'
        );
        const slaAssignTime = DateTime.fromJSDate(new Date(CreatedDate));

        if (
          SlaState.Name === TicketSlaState.Breached ||
          SlaState.Name === TicketSlaState.Satisfied
        ) {
          const _modifiedDate = DateTime.fromJSDate(new Date(ModifiedDate));
          const diff = durationCalculator(
            _modifiedDate,
            slaAssignTime,
            _breach
          );
          _sla['duration'] = diff;
          _sla['style'] = PillsColors[SlaState.Name];
          _sla['timeStamp'] = _timeStamp;
          _sla['state'] = SlaState.Name;
          _sla['stateString'] = SlaStateStrings[SlaState.Name];
        } else {
          const diff = durationCalculator(
            currentDateAndTime,
            slaAssignTime,
            _breach
          );
          _sla['duration'] = diff;
          _sla['style'] = PillsColors[SlaState.Name];
          _sla['timeStamp'] = _timeStamp;
          _sla['state'] = SlaState.Name;
          _sla['stateString'] = SlaStateStrings[SlaState.Name];
        }

        _slaData.push(_sla);
      }
    );
    return _slaData;
  };
  const {t} = useTranslation();
  const slas = calculateSlaBreaching(ticketSlas);
  const renderSlasPills = () => (
    <>
      {slas.length > 0 ? (
        <>
          {slas.slice(0, pillsToShow).map(({ name, style, duration, state, timeStamp, stateString }, _index) => (
            <div key={_index} className={`font-bold relative inline-block cursor-pointer ${Styles['SlaPills']}`}>
              <span className={Styles.Pills} style={{ ...style }}>
                {state === TicketSlaState.Breached || state === TicketSlaState.Satisfied
                  ? stateString
                  : duration}
              </span>
              <div className={Styles.tooltipcontent}>
                <div className="px-2">
                  <p className={`${Styles.SlaName} ${Styles.MarginBottom} font-medium`}>{name}</p>
                  <p className={`${Styles.SlaDesc} ${Styles.MarginBottom} font-medium`}>
                    {stateString}{' '}
                    {state !== TicketSlaState.Breached && state !== TicketSlaState.Satisfied && (
                      <span className="mx-0.5" style={{ color: style.color }}>
                        {duration}
                      </span>
                    )}
                  </p>
                  <p className={Styles.SlaTime}>{timeStamp}</p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <Text size="small" weight="regular">{t('ticket-details.ticket-details-card.ticket-details-info.none')}</Text>
      )}
    </>
  );

  return (
    <FluentUIThemeConsumer render={_globalTheme => (
        <Flex vAlign="center" gap="gap.small">
          {viewType !== 'List' && (<Flex vAlign="center" {...(svgGap ? { gap: 'gap.smaller' } : {})}>
            <BreachingInSVG width={14} height={14} {...(viewType === 'Edit' && { style: { marginRight: '0.3rem' } })}/> 
            <Title text={TICKET_DETAIL.SLAS} />
          </Flex>)}
          <div className="flex items-center gap-1">
            {isLoading ? (
              <Skeleton animation="wave">
                <Flex gap="gap.small">
                  <Skeleton.Shape {...SkeletonItemStyle} />
                  <Skeleton.Shape {...SkeletonItemStyle} />
                  <Skeleton.Shape {...SkeletonItemStyle} />
                </Flex>
              </Skeleton>
            ) : (
              renderSlasPills()
            )}
            {slas.length > pillsToShow && (
              <div className={`${Styles.tooltip} relative inline-block cursor-pointer`}>
                +{slas.slice(pillsToShow).length}
                <div className={Styles.tooltipcontent}>
                  <div className="flex flex-col h-full overflow-auto">
                    {slas.slice(pillsToShow).map(({ name, state, style, duration, timeStamp, stateString}, index) => (
                        <div key={index} className={`${Styles.SlaItem} px-2`}>
                          <p className={`${Styles.SlaName} ${Styles.MarginBottom} font-medium`}>{name}</p>
                          <p className={`${Styles.SlaDesc} ${Styles.MarginBottom} font-medium`}>
                            {stateString}{' '}
                            {state !== TicketSlaState.Breached && state !== TicketSlaState.Satisfied && (
                              <span className="mx-0.5" style={{ color: style.color }}>
                                {duration}
                              </span>
                            )}
                          </p>
                          <p className={Styles.SlaTime}>{timeStamp}</p>
                        </div>)
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Flex>
      )}
    />
  );
};
