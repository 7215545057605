import React from "react";
import { ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { useParams } from "react-router-dom";
import { AppStateContext, appState } from "../../../../AppState";
import { NewAttachmentFlowContent } from "./NewAttachmentFlowContent";

export const NewAttachmentFlowPage: React.FC = () => {
    const { ticketId, isEndUserPage } = useParams<any>();
    const currentState = appState();
    const isEndUser = (isEndUserPage?.toLowerCase() ?? "false") === "true";
    
    return (
        <AppStateContext.Consumer>
      {(state) => {
        return (
            <FluentUIThemeConsumer
        render={globalTheme => (
            <div className={`h-screen px-10 py-2`}>
                <NewAttachmentFlowContent globalTheme={globalTheme} ticketId={ticketId} currentFiles={[]} setCurrentFiles={null} filesToUpload={[]} onInteraction={() => {}} teamsConfig={currentState.teamsConfig} isEndUserPage={isEndUser}/>
            </div>
        )} />
        );
      }}    
    </AppStateContext.Consumer>)
}