import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Flex,
  Divider,
  Text,
  ThemePrepared,
  Button,
  EditIcon,
  SearchIcon,
  CloseIcon,
  Input,
} from '@fluentui/react-northstar';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';

import { SkeletonBoardHeader } from '../../shared/components/Skeleton';
import { TableMenu } from './TableMenu';
import { BoardMenu } from '../BoardMenu';
import { ChartMenu } from '../ChartMenu';
import { Filter } from '../../shared/components/Filter';
import { isCloseFilter, defaultToggleButtonList, toggleButtonMapper } from '../ticketHelper';
import { TButtonProps, ToggleButton } from '../../shared/components/customized-buttons/ToggleButton';
import { useHistory } from 'react-router-dom';
import { AddTicketDialog } from '../AddTicketDialog';
import { FontIcon } from '@fluentui/react';
import { appState, useSetState } from '../../AppState';
import AppCSS from '../Tikit.module.css';
import { ListContext } from '../List/ListContextProvider';
import { CustomViewContext } from './CustomViewContextProvider';
import { checkIfDefaultFilter } from './CustomViewPopup/heplers/customPopupHelper';
import { useTranslation } from 'react-i18next';
import ReportsSvg from '../../../svg/report-icon.svg';
import { MyWorkBoardSvg, MyWorkListSvg } from '../../../svg';

interface Props {
  isCustomViewEnabled: boolean;
  isFiltersFetching: boolean;
  isUserLicensed: boolean;
  globalTheme: ThemePrepared<any>;
  page: string | undefined;
  filters: FilterPropsItems[];
  setFilters: (filters: FilterPropsItems[]) => void;
  defaultFilters: FilterPropsItems[];
  isEditMode: boolean;
  onEditBulkHandler: () => void;
}

export const TicketToolbar = ({
  isCustomViewEnabled,
  isFiltersFetching,
  isUserLicensed,
  globalTheme,
  page,
  filters,
  setFilters,
  defaultFilters,
  isEditMode,
  onEditBulkHandler
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentstate = appState();
  const setState = useSetState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [toggleButtonList, setToggleButtonList] = useState<TButtonProps[]>([]);
  const { listStates, setListStates } = useContext(ListContext);
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);

  useEffect(() => {
    const mapper: Record<string, number> = {
      undefined: 0,
      table: 0,
      board: 1,
      chart: 2,
    };

    const toggleButtons = [...defaultToggleButtonList];
    toggleButtons[mapper[page]] = true;

    configureToggleButtonList(toggleButtons);
  }, [page]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        setIsSearchOpen(false);
      }
    });
  }, []);

  const filterChange = useCallback((action: ActionProps) => {
    if (action.event === 'getData') {
      setFilters(action.data.filterValues);
    }
    if (action.event === 'isOpen') {
      setIsFilterOpen(action.data.isOpen);
    }
    if (action.event === 'clearData') {
      setFilters(JSON.parse(JSON.stringify(defaultFilters)));
    }
  }, [defaultFilters, setFilters, setIsFilterOpen]);

  const configureToggleButtonList = (isActiveArray: boolean[]) => {
    setToggleButtonList([
      {
        name: t('ticket.toolbar.tickets-list'),
        isActive: isActiveArray[0],
        iconComponent: MyWorkListSvg
      },
      {
        name: t('ticket.toolbar.tickets-board'),
        isActive: isActiveArray[1],
        iconComponent: MyWorkBoardSvg
      },
      {
        name: t('ticket.toolbar.reports'),
        isActive: isActiveArray[2],
        iconComponent: ReportsSvg
      },
    ]);
  };
  const onClickSearchOpen = useCallback((status: boolean) => () => {
    setIsSearchOpen(status);
  }, [setIsSearchOpen]);

  const onClickRefresh = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        isReload: true,
        filtersApplyCount: 0,
        listFilter: checkIfDefaultFilter(state.listFilter) ? state.listFilter : undefined,
        isFilterApplied: false,
        ...(state.filterDropdown.queryValue && {
          filterDropdown: { ...state.filterDropdown, queryValue: '' },
          filterDropdownCount: 0,
          isFilterApplied: false
        }),
      };
    });
    setListStates(prev => ({ ...prev, isTicketsFetching: true }));
    setCustomViewStates((prev): CustomViewStatesType => ({ ...prev, selectedCustomView: null }));
  }, [currentstate, listStates, customViewStates]);

  const onChangeInput = useCallback((_e, p) => {
    setState((state) => {
      return {
        ...state,
        filterDropdown: { ...state.filterDropdown, queryValue: p.value }
      };
    });
  }, [currentstate, setState]);

  const onChangeToggleButton = useCallback((data) => {
    const toggleButtons = [...defaultToggleButtonList];
    toggleButtons[data.buttonActive] = true;
    configureToggleButtonList(toggleButtons);

    history.push(`/tickets/${toggleButtonMapper[data.buttonActive]}${location.search}`);
  }, [defaultToggleButtonList, configureToggleButtonList]);

  const toolbarMenu = (searchInputOpen) => {
    if (searchInputOpen) {
      return (
        <div className='block md:hidden w-full h-full'>
          <Input
            clearable
            fluid
            autoFocus
            className={`${AppCSS.searchInput} w-full flex`}
            onChange={onChangeInput}
            icon={
              <CloseIcon
                onClick={onClickSearchOpen(false)}
              />
            }
            inverted
            placeholder={t('ticket.child-filter.filter-keyword')}
          />
        </div>
      );
    } else {
      return (
        <Flex vAlign='center' className='mb-0'>
          <Flex.Item grow>
            <Flex>
              <div className={`hidden md:block`}>
                <Text
                  content={t('ticket.toolbar.tickets')}
                  weight='bold'
                  styles={{
                    marginLeft: '16px',
                    marginRight: '4px',
                    alignItems: 'center',
                    display: 'inline-flex',
                    height: '48px',
                  }}
                />
              </div>

              <div className={`hidden md:block`}>
                <Divider
                  vertical
                  color='gray'
                  style={{ height: '40px', marginTop: '4px' }}
                />
              </div>

              <AddTicketDialog isUserLicensed={isUserLicensed} globalTheme={globalTheme} page={page} />

              <div className={`hidden md:block mx-2`}>
                <Button
                  primary
                  text
                  icon={<FontIcon iconName={'Refresh'} />}
                  title={t('ticket.toolbar.refresh')}
                  content={t('ticket.toolbar.refresh')}
                  onClick={onClickRefresh}
                  className={`mt-2`}
                  style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground, marginLeft: -10 }}
                />
              </div>

              {isEditMode && !isCloseFilter(currentstate.listFilter) && (
                <div className={`hidden md:block mx-2`}>
                  <Button primary text title={t('common.buttons.edit')} content={t('common.buttons.edit')} className={`mt-2`}
                    icon={<EditIcon size="small" />}
                    onClick={onEditBulkHandler}
                    style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground, marginLeft: -20 }}
                  />
                </div>
              )}

              <Flex.Item className={`md:hidden`} grow>
                <div>&nbsp;</div>
              </Flex.Item>
              <div className={`md:hidden`}>
                <TableMenu defaultFilters={defaultFilters} globalTheme={globalTheme} isCustomViewEnabled={isCustomViewEnabled} />
              </div>
              <div className={`block md:hidden`}>
                <Divider
                  vertical
                  color='gray'
                  style={{
                    height: '40px',
                    marginTop: '4px',
                  }}
                />
              </div>
              <div className={`mt-3 ml-1 mr-2 md:hidden`}>
                <SearchIcon
                  size='large'
                  styles={{ color: '#424242' }}
                  onClick={onClickSearchOpen(true)}
                />
              </div>
            </Flex>
          </Flex.Item>

          <Flex.Item>
            <div className={`hidden md:flex items-center`}>
              <Flex>
                {page === undefined || page === 'table' ? (
                  <TableMenu defaultFilters={defaultFilters} globalTheme={globalTheme} isCustomViewEnabled={isCustomViewEnabled} />
                ) : page === 'board' ? (
                  <>
                    <TableMenu defaultFilters={defaultFilters} globalTheme={globalTheme} isCustomViewEnabled={isCustomViewEnabled} />
                    <BoardMenu />
                  </>
                ) : (
                  <ChartMenu />
                )}
              </Flex>
              {(page === undefined || page === 'table' || page === 'board') && (<Flex>
                <Filter
                  page={page}
                  onChange={filterChange}
                  items={filters}
                  open={isFilterOpen}
                  defaultFilters={defaultFilters}
                />
              </Flex>)}
              <Flex vAlign='center'>
                <Divider
                  vertical
                  color='gray'
                  style={{
                    height: '40px',
                    marginTop: '4px',
                  }}
                />

                <ToggleButton
                  buttonList={toggleButtonList}
                  onChange={onChangeToggleButton}
                />
              </Flex>
            </div>
          </Flex.Item>
        </Flex>
      );
    }
  };

  return (
    <ToolbarTheme globalTheme={globalTheme}>
      {isFiltersFetching ? (
        <SkeletonBoardHeader />
      ) : (
        <>{toolbarMenu(isSearchOpen)}</>
      )}
    </ToolbarTheme>
  );
};
