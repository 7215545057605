import React, { useEffect, useState } from 'react';
import {
  ProviderConsumer,
  Flex,
  Button,
  Input,
  Text,
  TextArea
} from '@fluentui/react-northstar';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import TemplateFieldsForm from './TemplateFieldsForm';
import { ITemplateFormProperties } from './TicketTemplates';
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import MgtCss from '../mgt.module.css';
import { TEMPLATE } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';

export const Step_DefineFields = (props: ITemplateFormProperties) => {
  const {t}  = useTranslation();
  const { headerContent, templateData, formData, dispatchData } = props;
  const isNameInvalid =
    templateData &&
    templateData.validateFields &&
    (!templateData.Name ||
      (templateData.Name && templateData.Name.length == 0) ||
      templateData.errorFieldsArray.some(x => x == 'DupeName'));
  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <Flex.Item size="size.half">
            <Flex column style={{ flex: 1, maxHeight: '70vh' }} gap="gap.medium">
              <Flex.Item>
                <>
                  {headerContent && <>{headerContent}</>}
                  {!headerContent && (
                    <Text
                      content={TEMPLATE.TEMPLATE_HEADER}
                      weight="bold"
                      size="large"
                    />
                  )}
                </>
              </Flex.Item>
              <Flex.Item>
                <div
                  style={{
                    height: 'inherit',
                    ...ThemeColorScheme(globalTheme.siteVariables)
                  }}
                >
                  <div className={MgtCss.ticketForm}>
                    <Flex style={{ marginBottom: '0.5em' }}>
                      <Text
                        content={t('template.fields.template-name')}
                        style={{ display: 'block' }}
                      />
                      <span style={{ color: '#c4314b', marginLeft: 2 }}>*</span>
                    </Flex>
                    <Input
                      autoFocus
                      required
                      fluid
                      defaultValue={templateData.Name}
                      error={isNameInvalid}
                      onChange={(e, { value }) => {
                        templateData.Name = value;
                        templateData.errorFieldsArray =
                          templateData.errorFieldsArray.filter(
                            x => x != 'DupeName'
                          );
                        dispatchData(templateData);
                      }}
                      onBlur={e => {
                        if (
                          !(
                            !templateData.Name ||
                            (templateData.Name && templateData.Name.length == 0)
                          )
                        ) {
                          templateData.errorFieldsArray =
                            templateData.errorFieldsArray.filter(
                              x => x != 'Name' && x != 'DupeName'
                            );
                          dispatchData(templateData);
                        }
                      }}
                      styles={{ marginBottom: isNameInvalid ? '0' : '1em' }}
                    />
                    {isNameInvalid && (
                      <div style={{ color: '#c4314b', marginBottom: '1em' }}>
                        {!templateData.Name ||
                        (templateData.Name && templateData.Name.length == 0)
                          ? TEMPLATE.ERROR_NAME_REQUIRED
                          : TEMPLATE.ERROR_NAME_EXIST}
                      </div>
                    )}
                  </div>
                  <div className={MgtCss.ticketForm}>
                    <Flex style={{ marginBottom: '0.5em' }}>
                      <Text
                        content={t('template.fields.template-description')}
                        style={{ display: 'block' }}
                      />
                    </Flex>
                    <TextArea
                      fluid
                      defaultValue={templateData.Description}
                      onChange={(e, { value }) => {
                        templateData.Description = value;
                        dispatchData(templateData);
                      }}
                    />
                  </div>
                </div>
              </Flex.Item>
              <Flex.Item>
                <div>
                  <Flex>
                    <Text
                      content={TEMPLATE.TEMPLATE_PROPERTIES}
                      weight="bold"
                      size="large"
                    />
                  </Flex>
                  <Flex>
                    <Text content={TEMPLATE.TEMPLATE_PROPS_DESC} />
                  </Flex>
                </div>
              </Flex.Item>
              <Flex.Item>
                <TemplateFieldsForm {...props} />
              </Flex.Item>
            </Flex>
          </Flex.Item>
          <Flex.Item size="size.half">
            <div></div>
          </Flex.Item>
        </>
      )}
    />
  );
};
