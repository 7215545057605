import React, { useEffect, useState } from 'react';
import {
  Flex,
  ProviderConsumer as FluentUIThemeConsumer,
  Text
} from '@fluentui/react-northstar';
import { PlatformUser } from '../../../shared/interfaces/platformuser.interface';
import { appState } from '../../../AppState';
import { UserPic } from '../../EditTicket/UserPic';
import { getFileIcon } from '../../../shared/components/EditTicketAttachments/NewAttachmentFlow/NewAttachmentFlowContent';

interface Props {
  ticket: Ticket;
  view: 'list' | 'edit';
}

export const getPUserById = (id: number, users: PlatformUser[]) =>
  users.find(({ Id }) => Id === id);

export const LastConvo = ({ ticket, view = 'list' }: Props) => {
  const currentState = appState();
  const [user, setUser] = useState({
    Id: 0,
    FullName: '',
    Email: ''
  });
  const mystyle = {
     width: '244px',
     overflow:'hidden',
     whiteSpace:'nowrap',
     textOverflow: 'ellipsis'
  }  as React.CSSProperties;;

  const [comment, setComment] = useState('');
  const [attachment, setAttachment] = useState<any>();

  useEffect(() => {
    const comment = ticket['Comments'].filter(x=>x.IsPublic).slice(-1).pop();
    let _attachment = ticket.FileAttachments.find(x=>x.FileCommentId==comment.Id);
    if(_attachment && comment['Body']=="<tikit-comment-empty></tikit-comment-empty>"){
      setAttachment(_attachment);
      comment['Body']=_attachment.FileName;
    }
    
    if (comment) {
      setComment(comment['Body']);
      const { Id, Email, FullName } =
        getPUserById(comment['CreatedById'], currentState.platformusers) || {};
      setUser({ Id, FullName, Email });
    }
  }, [ticket, currentState.platformusers]);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <div className="mt-2 mb-2">
            <Flex vAlign="start">
              <Flex.Item>
                <UserPic id={user.Id} name={user.FullName} email={user.Email} />
              </Flex.Item>
              <Flex.Item>
                <div className="pl-2">
                  <div>
                    <Text
                      size="small"
                      weight="bold"
                      content={(user.FullName = '')}
                    />
                  </div>
                  <div style={mystyle}>
                  {attachment && 
                    <>
                      {getFileIcon(attachment.FileExtension, attachment, globalTheme)}
                    </>
                  }
                    <Text size="small" content={comment} />
                  </div>
                </div>
              </Flex.Item>
            </Flex>
          </div>
        </>
      )}
    />
  );
};
