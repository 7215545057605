import React from 'react';
import range from 'lodash/range';
import { Person, PersonCardInteraction } from '@microsoft/mgt-react';
import { UsersTooltip } from '../../../../shared/components/TicketDetailInfo/UsersTooltip';
import { UserAvatar } from '../../../../shared/components/UserAvatar';
import { appState } from '../../../../AppState';
import { SpeakerPersonIcon } from '@fluentui/react-icons-northstar';
import { useTranslation } from 'react-i18next';

export interface GroupUsersIconProps {
  usersList: { PlatformUserId: number }[];
  maxLength: number;
  isListing?: boolean;
}

export const GroupUsersIcon = ({ usersList, maxLength, isListing = false }: GroupUsersIconProps) => {
  const currentState = appState();
  const { t } = useTranslation();
  
  maxLength = maxLength + 1;
  return (
    <>
      {range(0, Math.min(usersList?.length, maxLength)).map(i => {
          if ((i+1) === maxLength) {
            const list = usersList.slice(i, usersList.length).map(u => currentState.platformusers.find(p => p.Id == u.PlatformUserId));
            return (<UsersTooltip ticketCollaborator={list} isPluseShow={true} position={'top'} title={t('side-nav.users')} />);
          } else {
            const user = currentState.platformusers.find(x => x.Id == usersList[i].PlatformUserId);
            return (
              <span className={`mr-1`}>
                {!isListing && <Person personDetails={{ displayName: user?.FullName, mail: user?.Email, id: user?.AadObjectId, userPrincipalName: user?.UserName }} fetchImage avatarSize="auto" personCardInteraction={PersonCardInteraction.hover} />}
                {isListing && user && <UserAvatar {...{
                    idOrUpn: user?.AadObjectId ?? user?.UserName,
                    avatarProps: { name: user?.FullName, size: 'smaller', className: 'cui-ml-2' },
                  }} />}
                {isListing && !user && <SpeakerPersonIcon size="large" style={{ color: '#8f90a6' }} variables={{ largeSize: '24px' }} />}
              </span>
            );
          }
        })}
    </>
  );
};
