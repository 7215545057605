import { Configuration, PopupRequest } from "@azure/msal-browser";
import * as jwt from 'jsonwebtoken';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "ae4e6ff1-3b9f-4f34-9485-2d243a746f79",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: 'http://localhost:8080/',
        navigateToLoginRequestUrl: true,
        
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["openid", "profile", "Directory.AccessAsUser.All", "Group.Read.All", "User.Read", "User.ReadBasic.All"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const graphConfigPhoto = (email?: string) => {
    if (typeof email === "undefined") {
        return "https://graph.microsoft.com/v1.0/me/photo/$value"
    }
    else {
        return `https://graph.microsoft.com/v1.0/users/${email}/photo/48x48`
    }
};



export const getUser = (): string => {
    let token = localStorage.getItem("token") || localStorage.getItem(`msal.${window.__runtimeConfig.msalClientId}.idtoken`);
    
    if(typeof token === "undefined"){
        return "";
    }
    else {
        const decoded: { [key: string]: any; } = jwt.decode(token);
        let email = decoded.preferred_username;
        if(typeof email === "undefined") {
            email = decoded.unique_name
        }
        return email;
    }
}