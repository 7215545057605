import { Subject } from 'rxjs';
import _ from "lodash";
import { platformService } from './platform.service';
import { KBData, SynonymData, ToUpdateKB } from '../../AppState';

const subject = new Subject();

let data = [];
let initData = [];
let deleteIds = [];
let editIds = [];

let initSynonyms: SynonymData[] = [];
let synonyms: SynonymData[] = [];

const client = new platformService();

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
};
const waitOnKb = async (job) => {
    let rsp = null;
    let retry = 0;
    
    while (job.location && job.location.length > 0) {
        try {
            rsp = await kbDataService.checkAPI(job.location);
            retry = 0;
            job = rsp.data;
            if (job.location && job.location.length > 0)
                await sleep(750);
        } catch (e) {
            retry++;
            if (retry > 3) throw e;
            await sleep(1000);
        }
    }
    
    return job;
}

export const kbDataService = {
    initData: (givenData, givenSynonyms) => {
        initData = givenData;
        data = givenData;

        initSynonyms = givenSynonyms;
        synonyms = givenSynonyms;
    },
    listen: () => subject.asObservable(),
    setObject: givenObj => {
        if (!_.isEmpty(givenObj))
            subject.next(givenObj);
    },
    setData: givenData => {
        data = givenData;
        subject.next({ data: data });
    },
    getData: () => {
        return data.sort(function (a, b) {
            return b["id"] - a["id"];
        });
    },
    setEdittedAnswer: (id, value) => {

    },
    getOneData: id => {
        return data.find(x => x["id"] == id);
    },
    setOneData: (id, field, dataIndex, value) => {
        let i = data.findIndex(x => x["id"] == id);
        if (i >= 0) {
            if (field === "questions") {
                let questions = data[i][field];
                questions[dataIndex] = value;
                value = questions;
            }
            data[i][field] = value;
            if (editIds.indexOf(data[i]["id"]) == -1) editIds.push(data[i]["id"]);
        }
        subject.next({ isDirty: true, updateData: false });
    },

    addOneRow: (questions?: string[], answer?: string) => {
        const id = data.length > 0 ? (data[0].id + 1) as number : 1;

        let newItem = { answer: answer ? answer : "", questions: questions ? questions : [], id: id, source: "kbnew" };
        data.unshift(newItem);
        subject.next({ data: data, isDirty: true });
    },
    deleteOneRow: id => {
        let i = data.findIndex(x => x["id"] == id);
        if (i >= 0) {
            if (deleteIds.indexOf(data[i]["id"]) == -1) deleteIds.push(data[i]["id"]);
            data.splice(i, 1);
            subject.next({ data: data, isDirty: true });
        }
    },
    removeOneQuestion: (id, dataIndex) => {
        let i = data.findIndex(x => x["id"] == id);
        if (i >= 0) {
            let questions = data[i]["questions"];
            questions.splice(dataIndex, 1);
            data[i]["questions"] = questions;
            if (editIds.indexOf(data[i]["id"]) == -1) editIds.push(data[i]["id"]);
            subject.next({ isDirty: true, updateData: false });
        }
    },
    addOneQuestion: (id) => {
        let i = data.findIndex(x => x["id"] == id);
        if (i >= 0) {
            data[i]["questions"].push(" ");
            if (editIds.indexOf(data[i]["id"]) == -1) editIds.push(data[i]["id"]);
            subject.next({ data: data, isDirty: true });
        }
    },
    clearData: () => {
        data = [];
        deleteIds = [];
        editIds = [];
        subject.next({ data: data, isDirty: true });
    },
    afterSavedData: () => {
        deleteIds = [];
        editIds = [];
        initData = data;
        subject.next({ loading: false, isDirty: false, updateData: false });
    },
    data,
    saveAPI: (stateKBData?: KBData[]) => {
        subject.next({ loading: true, loadingMessage: "Updating your knowledge base with the changes you made..." });
        let newData: KBData[] = data.filter(x => x.source == "kbnew");
        let newDataIds = newData.map(x => x.id);
        let localdata = data;
        if (stateKBData) {
            newData = stateKBData.filter(x => x.source == "kbnew");
            newDataIds = newData.map(x => x.id);
        }

        if (newData.length > 0) {
            return kbDataService.addAPI(newData).then(response => {
                editIds = editIds.filter(x => !newDataIds.includes(x));
                deleteIds = deleteIds.filter(x => !newDataIds.includes(x));
                localdata = localdata.map(x => {
                    if (x.source == "kbnew") {
                        x["source"] = "Editorial";
                        x["metadata"] = [];
                        x["context"] = {
                            "isContextOnly": false,
                            "prompts": []
                        }
                    }
                    return x;
                });
                if (editIds.length > 0 || deleteIds.length > 0) {
                    return kbDataService.updateAPI().then(response => {
                        kbDataService.afterSavedData();
                        return response.data;
                    }).catch((e: any) => {
                        kbDataService.afterSavedData();
                        subject.next({ errorCode: e.response.status });
                        return false;
                    });
                } else {
                    kbDataService.afterSavedData();
                    return response.data;
                }
            });
        } else if (editIds.length > 0 || deleteIds.length > 0) {
            return kbDataService.updateAPI().then(response => {
                kbDataService.afterSavedData();
                return response.data;
            }).catch((e: any) => {
                kbDataService.afterSavedData();
                subject.next({ errorCode: e.response.status });
                return false;
            });
        } else {
            return new Promise(resolve => {
                setTimeout(x => { subject.next({ loading: false, isDirty: false, updateData: false }); return true; }, 300);
            });
        }

    },
    fetchAPI: () => {
        return client.init().get("GetQnAKnowledgebaseItems(FilterSource='Editorial')")
    },
    updateAPI: async (currentData?: KBData[], stateEditIds?: number[], stateDeleteIds?: number[]) => {
        let updatedData = data.filter(x => editIds.indexOf(x["id"]) != -1).sort(function (a, b) {
            return a["id"] - b["id"];
        });
        if (currentData) {
            updatedData = currentData.filter(x => stateEditIds.indexOf(x["id"]) != -1).sort(function (a, b) {
                return a["id"] - b["id"];
            });
        }
        const rsp = await client.init().post('ReplaceKnowledgebase', { UpdateList: JSON.stringify(updatedData), RemoveIdList: JSON.stringify(stateDeleteIds) });
        const job = await waitOnKb(rsp.data);
        return job.status === "succeeded" ? kbDataService.publishAPI() : job;
    },
    updateSingleKB: async (updatedData: KBData) => {
        const rsp = await client.init().post('ReplaceKnowledgebase', { UpdateList: JSON.stringify([updatedData]), RemoveIdList: JSON.stringify([]) });
        const job = await waitOnKb(rsp.data);
        return job.status === "succeeded" ? kbDataService.publishAPI() : job;
    },
    deleteAPI: async (ids) => {
        const rsp = await client.init().post('DeleteQnA', { Ids: ids });
        const job = await waitOnKb(rsp.data);
        return job.status === "succeeded" ? kbDataService.publishAPI() : job;
    },
    addAPI: async (newData) => {
        const rsp = await client.init().post('AddToKnowledgebase', { QnaPairs: JSON.stringify(newData) });
        const job = await waitOnKb(rsp.data);
        return job.status === "succeeded" ? kbDataService.publishAPI() : job;
    },
    checkAPI: (location: string) => client.init().get(`CheckKnowledgebase?location=${encodeURIComponent(location)}`),
    publishAPI: async () => {
        const rsp = await client.init().post('PublishKnowledgebase', {});
        await sleep(500); // We need this sleep to let other operations finish, I'm not sure why this causes an error suddenly
        return waitOnKb(rsp.data);
    },

    fetchSynonyms: () => client.init().get('GetSynonyms'),
    saveSynonyms: async (data: SynonymData[]) => {
        const rsp = await client.init().post('ReplaceSynonyms', { Synonyms: data });
        return rsp.data;
    }
};

export const KBStateService = {
    addOneQuestion: (id, kbstate: KBData[]) => {
        let i = kbstate.findIndex(x => x.id == id);
        if (i >= 0) {
            kbstate[i].questions.push(" ");
        }
        return kbstate
    },
    addOneRow: (questions: string[], answer: string, kbstate: KBData[]) => {
        const id = kbstate.length > 0 ? (kbstate[0].id + 1) as number : 1;

        let newItem: KBData = {
            answer: answer || "", questions: questions ? questions.filter(x => (x || '').trim() !== '') : [], id: id, source: "kbnew",
            status: '',
            modifiedById: 0,
            createdById: 0,
            lastUpdatedDateTime: '',
            agentOnly: false
        };
        return newItem;
    },
    deleteOneRow: (id: number, kbstate: KBData[]) => {
        let newList = kbstate.filter(x => x.id !== id);

        return newList;
    },
    saveAPI: (stateKBData: KBData[], stateEdittedKBIds: ToUpdateKB[], stateDeletedKBIds: number[]) => {
        let newData = stateKBData.filter(x => x.source == "kbnew");
        let newDataIds = newData.map(x => x.id);
        let localdata = stateKBData;

        let edittedIds: number[] = [];
        stateEdittedKBIds.map((kb) => {
            edittedIds.push(kb.id)
        })


        stateEdittedKBIds.map(edittedKB => {
            const currentKB = stateKBData.findIndex(stateKB => edittedKB.id === stateKB.id);
            if (currentKB >= 0) {
                if (edittedKB.answer) {
                    stateKBData[currentKB].answer = edittedKB.answer
                }
                if (edittedKB.question) {
                    stateKBData[currentKB].questions[edittedKB.question.index] = edittedKB.question.content
                }
                if (edittedKB.removedQuestionsIndex) {
                    stateKBData[currentKB].questions = stateKBData[currentKB].questions.filter((q, i) => !edittedKB.removedQuestionsIndex.includes(i))
                }

            }
        })


        if (newData.length > 0) {
            return kbDataService.addAPI(newData).then(response => {
                editIds = edittedIds.filter(x => !newDataIds.includes(x));
                deleteIds = stateDeletedKBIds.filter(x => !newDataIds.includes(x));
                localdata = localdata.map(x => {
                    if (x.source == "kbnew") {
                        x["source"] = "Editorial";
                        x["metadata"] = [];
                        x["context"] = {
                            "isContextOnly": false,
                            "prompts": []
                        }
                    }
                    return x;
                });
                if (editIds.length > 0 || deleteIds.length > 0) {
                    return kbDataService.updateAPI(stateKBData).then(response => {
                        return response.data;
                    }).catch((e) => {
                        console.log(e)
                        return false;
                    });
                } else {
                    return response.data;
                }
            });
        } else if (stateEdittedKBIds.length > 0 || stateDeletedKBIds.length > 0) {
            return kbDataService.updateAPI(stateKBData, edittedIds, stateDeletedKBIds).then(response => {
                return response.data;
            }).catch((e) => {
                console.log(e);
                return false;
            });
        } else {
            return new Promise(resolve => {
                setTimeout(x => {
                    resolve('success')
                    return true;
                }, 300);
            });
        }
    },
}