import React from 'react';
import Styles from './UsersTooltip.module.css';
import { SpeakerPersonIcon, Text, ProviderConsumer as FluentUIThemeConsumer, } from '@fluentui/react-northstar';
import { UserAvatar } from '../UserAvatar';
import { ThemeColorScheme } from '../../common/TeamsTheme';

interface UsersTooltipProps {
  ticketCollaborator: PlatormUserEntity[];
  isPluseShow?: boolean;
  position?: string;
  title?: string;
}

export const UsersTooltip = ({
  ticketCollaborator,
  isPluseShow,
  position,
  title
}: UsersTooltipProps) => {
  return (
    <FluentUIThemeConsumer render={globalTheme => (
      <div className={`${Styles.tooltip} inline-block cursor-pointer`} style={{ position: 'relative', ...ThemeColorScheme(globalTheme.siteVariables) }}>
        <Text size="small" className={`ml-1 cursor-pointer`} style={{ color: globalTheme.siteVariables.colorScheme.default.foreground }}>
          {isPluseShow && '+'}
          {ticketCollaborator?.length ?? 0}
        </Text>
        {ticketCollaborator?.length > 0 && (
          <div className={`${Styles.tooltipcontent} ${position === 'top' ? Styles.top : Styles.bottom}`} >
            <div>
              <div className={`font-sans font-medium py-1.5 pl-2 ${Styles.borderBottom}`}>
                {title}
              </div>
              <div className={`flex flex-col ${position === 'top' ? Styles.tooltipInerConnentForTop : Styles.tooltipInerConnentForBottom} overflow-auto`}>
                {ticketCollaborator.map(({ FullName, AadObjectId, UserName }, index) => {
                  return (
                    <div key={index} className={`pl-2 py-1 ${index > 0 && Styles.borderTop}`}>
                      {(AadObjectId || UserName) ? (
                        <UserAvatar
                          {...{
                            idOrUpn: AadObjectId ?? UserName,
                            avatarProps: {
                              name: FullName,
                              size: 'smaller',
                              className: 'cui-ml-2'
                            }
                          }}
                        />
                      ) : (
                        <SpeakerPersonIcon
                          size="large"
                          style={{ color: '#8f90a6' }}
                          variables={{ largeSize: '24px' }}
                        />
                      )}
                      &nbsp;
                      {!FullName ? 'Unassigned' : FullName}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    )} />
  );
};
