import React from 'react';
import {
  Box,
  Flex,
  SpeakerPersonIcon,
  Text
} from '@fluentui/react-northstar';
import { Person, PersonCardInteraction } from '@microsoft/mgt-react';
import Styles from './GroupUsersCard.module.css';
import { useTranslation } from 'react-i18next';

export interface GroupUsersListProps {
  usersList: any[];
}

export const GroupUsersList = ({ usersList }: GroupUsersListProps) => {
  const { t } = useTranslation();
  
  return (
    <>
      {usersList.map(user => (
        <Flex vAlign="center" space='between'  className={`mt-3`}>
            <Flex>
              <Flex.Item>
                 <Box>
              <Box>
                {user?.FullName && user?.Email ? (
                  <Person
                    personDetails={{
                      displayName: user.FullName,
                      mail: user.Email
                    }}
                    fetchImage
                    avatarSize="auto"
                    personCardInteraction={PersonCardInteraction.hover}
                  />
                ) : (
                  <SpeakerPersonIcon
                    size="large"
                    style={{ color: '#8f90a6' }}
                    variables={{ largeSize: '24px' }}
                  />
                )}
              </Box>
                 </Box>
              </Flex.Item>
              <Flex.Item>
                 <Box className={`break-words`}>
              <Box className={`ml-2`}>
                <Flex>
                  <span>{user?.FullName ? user?.FullName : t('ticket.ticket-board.ticket-card.unassigned')}</span>
                </Flex>
              </Box>
                 </Box>
              </Flex.Item>
            </Flex>
            <Flex>
             <Flex.Item>
                <div><UserBadge user={user.userType} /></div>
             </Flex.Item>
            </Flex>
        </Flex>
      ))}
    </>
  );
};

export const UserBadge = ({ user }: { user: string }) => {
  return (
      <Flex hAlign="end">
        <div className={`inline-block capitalize pb-0.5 px-3 rounded-full ${Styles['userBadge']}`}>
          <Text weight="bold" size="smaller">
            {user}
          </Text>
        </div>
      </Flex>
  );
};
