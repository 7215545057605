import React, { useState } from "react";
import { Button, Flex, ProviderConsumer, Menu, tabListBehavior } from '@fluentui/react-northstar';
import { KBList } from "./KBList";
import { kbDataService } from '../shared/services/kb.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from "@microsoft/mgt-element/dist/es6/providers/Providers";
import { Prompt } from 'react-router';
import ConfigCSS from '../config/config.module.css';
import { Toolbar } from "../shared/components/Toolbar";
import { KBData, SynonymData } from "../AppState";
import { platformService } from "../shared/services/platform.service";
import { AppKBStateContext, useSetKBState } from "../AppKBState";
import { CheckLogin } from "../shared/components/CheckLogin";
import { Helmet } from 'react-helmet';
import { LoadingScreen } from "../shared/components/LoadingScreen";
import { pick } from "lodash";
import { KBToolbar } from "./KBToolbar";
import { SynonymTable } from "./SynonymList";
import { CONTACT_ADMIN, REFRESH_SESSION, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS, LICENSE_FEATURE, FILTERS_STRINGS } from "../shared/utils/constants";
import { useTranslation } from "react-i18next";
import { getCachedFeature } from "../shared/cache/FeatureCache";
import { UpgradeNeeded} from '../shared/components/UpgradeNeeded';

export function KBMain() {
  const {t} = useTranslation();
  const api = new platformService();

  const [data, setData] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(0);

  const [isError, setIsError] = useState(false);
  const [errCode, setErrCode] = useState(0);
  const [templates, setTemplates] = useState([]);

  let setState = useSetKBState();
  const fetchData = async () => {
    setState((state) => {
      return {
        ...state,
        loadingKB: {
          isLoading: true,
          message: t('knowledge.loading-knowledge')
        }
      }
    })
    let values = [];
    let synonyms = [];

    try {
      const result = await Promise.all([
        kbDataService.fetchAPI(),
        await api.getTicketTemplates(`${FILTERS_STRINGS.FILTERS_URL_PARAMS} and NoCustomForm eq false and CardResponseJson ne '' and CardResponseJson ne null`),
        kbDataService.fetchSynonyms(),
        getCachedFeature(LICENSE_FEATURE.TemplateDeflection),
        getCachedFeature(LICENSE_FEATURE.TicketDeflection)
      ]);

      values = JSON.parse(result[0].data.value);
      values = values.sort(function (a, b) {
        return b["id"] - a["id"];
      });

      let ids = Array.from(new Set(values.flatMap(obj => [obj.createdById, obj.modifiedById]).filter(id => id > 0))).join(',');
      let params = ids ? `?$filter=Id in (${ids})` : '';
      let users = [];
      if (params) {
        users = (await api.getUsers(params)).data.value;
      }

      synonyms = result[2].data.value;

      setState(state => {
        return {
          ...state,
          knowledgeBase: values,
          synonyms: synonyms,
          tab: 0,
          users: users
        }
      })
      kbDataService.initData(values, synonyms);
      setIsError(false);
      setTemplates(result[1].data.value);
    } catch (e: any) {
      setIsError(true);
      setErrCode(e.response.status);
    }

    setState((state) => {
      return {
        ...state,
        loadingKB: {
          ...state.loadingKB,
          isLoading: false
        }
      }
    })
    setIsLoading(false);
    setData((prevState) => {
      return Object.assign([], values);
    });
  };

  const loadPage = () => {
    fetchData();
  }

  var kblist = {
    title: "KB",
    find: true,
    emptySelectionActionGroups: {
      g1: {
        a1: { title: t('knowledge.add-qn-a-pair'), icon: "Add", subject: "add kb" },
      },
    },
    columns: {
      questions: {
        title: t('knowledge.questions')
      },
      answer: {
        title: t('knowledge.answer')
      },
      options: {
        title: "",
        minWidth: 20
      }
    },
    rows: {}
  };

  const processKB = (knowledgeBase: KBData[]) => {
    let processedKB = {
      title: "KB",
      find: true,
      emptySelectionActionGroups: {
        g1: {
          a1: { title: t('knowledge.add-qn-a-pair'), icon: "Add", subject: "add kb" },
        },
      },
      columns: {
        questions: {
          title: t('knowledge.questions')
        },
        answer: {
          title: t('knowledge.answer')
        },
        options: {
          title: "",
          minWidth: 20
        }
      },
      rows: {},
      templates: templates
    };
    if (knowledgeBase) {
      knowledgeBase.map((item: KBData) => {
        processedKB.rows["row-" + item.id] = {
          questions: item.questions.join("|"),
          answer: item.answer,
          options: ["delete"],
          id: item.id,
          source: item.source,
          createdById: item.createdById,
          modifiedById: item.modifiedById,
          status: item.status,
          agentOnly: item.agentOnly,
          lastUpdatedDateTime: item.lastUpdatedDateTime
        }
      })
    }
    return processedKB;
  }
  const processSynonyms = (synonyms: SynonymData[]) => {
    let processed = {
      title: "KB",
      find: true,
      emptySelectionActionGroups: {
        g1: {
          a1: { title: t('knowledge.add-synonym-pair'), icon: "Add", subject: "add synonym" },
        },
      },
      columns: {
        synonyms: {
          title: "Synonyms"
        },
        options: {
          title: "",
          minWidth: 20
        }
      },
      rows: {}
    };
    if (synonyms) {
      synonyms.forEach((item: SynonymData, idx: number) => {
        processed.rows["row-" + idx] = {
          alterations: item.alterations,
          options: ["delete"],
          id: idx
        }
      });
    }
    return processed;
  }

  const errConfig = {
    fields: {
      title: (errCode == 401 || errCode == 403) ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  }

  const KbBase = (props) => {
    const items = [
      {
        key: 'qnapairs',
        content: t('knowledge.qnapairs'),
      },
      {
        key: 'synonyms',
        content: t('knowledge.synonyms'),
      }
    ];

    const [isTicketDeflectionEnabled, setisTicketDeflectionEnabled] = useState(true);
    (async () => setisTicketDeflectionEnabled((await getCachedFeature(LICENSE_FEATURE.TicketDeflection))))();

    return (

      (isError) ? (<ProviderConsumer render={(globalTheme) => (
        <>
          <Toolbar globalTheme={globalTheme} title="Settings > Knowledge" hideSave={true} />
          <Flex className={ConfigCSS.configBody}>

            <Flex.Item grow>
              <>
                <Communication {...errConfig} />
                {errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
              </>
            </Flex.Item>
          </Flex>
        </>
      )} />)
        : <AppKBStateContext.Consumer>
        {(state) => {
          const toolbarProps = pick(props, [
            'filtersSingleSelect',
            'find',
            'onInteraction'
          ]);
          return (
            <ProviderConsumer render={(globalTheme) => (
              <>
               {!isTicketDeflectionEnabled && <UpgradeNeeded
                  toolbarText={t('tab-title.knowledge')}
                  headerText={t('knowledge.ticket-deflection.no-access')}  
                  subheaderText={t('knowledge.ticket-deflection.upgrade-plan')} /> }
                <KBToolbar
                  globalTheme={globalTheme}
                  state={state}
                  {...toolbarProps}
                  actionGroups={{}}
                  onInteraction={() => { }}
                  aria-controls="fluentui-teams__list-content"
                  aria-label="List content controls"
                />
                <Menu defaultActiveIndex={state.tab} items={items} underlined primary accessibility={tabListBehavior} aria-label="Knowledge "
                  onActiveIndexChange={(e, p: any) => {
                    setState(state => {
                      return {
                        ...state,
                        tab: p.activeIndex
                      };
                    });
                  }} />
                {(state.tab === 0) && (<KBList {...processKB(state.knowledgeBase)} ifEmpty={{ header: t('knowledge.empty.empty-knowledge.header'), subHeader: t('knowledge.empty.empty-knowledge.subHeader') }} />)}
                {(state.tab === 1) && (<SynonymTable {...processSynonyms(state.synonyms)} ifEmpty={{ header: t('knowledge.empty.empty-synonyms.header'), subHeader: t('knowledge.empty.empty-knowledge.subHeader') }} />)}
              </>
            )} />
          )
        }}
      </AppKBStateContext.Consumer>
        
    )
  }
  return (
    <CheckLogin onSignedIn={loadPage}>
      <AppKBStateContext.Consumer>
        {(state) => {
          return (
            <>
              <Helmet>
                <title>{t('tab-title.knowledge')} - {t('tab-title.postfix')}</title>
              </Helmet>
              <Prompt
                when={state.editedKB.length > 0}
                message={t('knowledge.massage')}
              />
              {(state.loadingKB.isLoading) ? <LoadingScreen message={state.loadingKB.message} /> : <KbBase />}
            </>
          )
        }}
      </AppKBStateContext.Consumer>
    </CheckLogin>
  )
}