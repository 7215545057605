import React, { createRef, useEffect, useState } from 'react';
import {
  Flex,
  Loader,
  List,
  Button,
  ListItem,
  Text,
  Skeleton,
  Alert,
  ThemePrepared,
  Grid,
  gridHorizontalBehavior,
  ChevronEndIcon,
  ChevronDownIcon,
  Dialog,
  CloseIcon
} from '@fluentui/react-northstar';
import { ExclamationTriangleIcon } from '@fluentui/react-icons-northstar';
import { platformService } from '../../services/platform.service';
import { graphService } from '../../services/graph.service';
import RelativeDate from '../RelativeDate';
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone';
import { Icon, Link, mergeStyleSets } from '@fluentui/react';
import { EmptyData } from '../EmptyData';
import EmptySVG from '../../../../svg/empty.svg';
import { debounce } from 'lodash';
import CSS from './EditTicketAttachments.module.css';
import { EditTicketEvent } from '../../../tikit/ticketHelper';
import { i18n } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { NewAttachmentFlowContent, getFileIcon, getTruncatedFilename } from './NewAttachmentFlow/NewAttachmentFlowContent';
import { ITeamsConfig } from '../../../config/TeamsConfig';

interface Props {
  isOpen: boolean;
  onCLose: (value: boolean) => void;
  primaryButtonColor: string;
  isSaving: boolean;
  onInteraction?: (interaction: any) => void;
  onToolbarInteraction?: (interaction: any) => void;
  globalTheme: ThemePrepared<any>;
  isTicketClosed: boolean;
  ticketId: number;
  acceptedFiles: File[];
  rejectedFiles: FileRejection[];
  fileTriggerChannel: string;
  isEndUserPage: boolean;
  fileAttachments: FileProps[];
  setAttachmentsCount: (count: number) => void;
  signedInUser: {
    FullName: string;
    Id: number;
    Email: string;
  };
  teamsConfig: ITeamsConfig;
}

export interface FileProps {
  Id?: number;
  FileName: string;
  DownladUrl?: string;
  FileExtension: string;
  FileSize?: number;
  ModifiedById?: number;
  ModifiedDate?: string;
  DriveItemGroupId?: string;
  DriveItemId?: string;
  MoveItemFailed: boolean | false;
  isHidden: boolean | false;
  isUploading: boolean | false;
  isUploadFailed: boolean | false;
  uploadFailedMessage: string;
  isPublic?: boolean | true;
  iconElement?: JSX.Element | null;
  ThumbnailUrl?: string;
}

const uploadFailedMessage = i18n().t('ticket-details.edit-ticket-form.attachments.upload-failed');
const uploadMaxSizeMB = 4;
const uploadMaxSizeBytes = uploadMaxSizeMB * Math.pow(1024, 2);

const iconByFileExtension: { name: string; types: string }[] = [
  { name: 'Page', types: 'unknown' },
  { name: 'FileBug', types: 'failed' },
  { name: 'Photo2', types: 'jpg gif png jpeg bmp ico tif tiff psd ps ai' },
  { name: 'WordDocument', types: 'doc docm docx odt wpd' },
  { name: 'TextDocument', types: 'txt rtf' },
  { name: 'ExcelDocument', types: 'xls xlsm xlsx ods csv' },
  { name: 'PowerPointDocument', types: 'key pps ppt pptx odp' },
  { name: 'ZipFolder', types: 'zip rar pkg 7z deb arj' },
  { name: 'PDF', types: 'pdf' },
  { name: 'FileSQL', types: 'sql' },
  { name: 'Database', types: 'dat db dbf mdb accdb' },
  { name: 'Mail', types: 'email  eml emlx msg oft ost pst vcf' },
  { name: 'FilePDB', types: 'bat bin exe apk wsf jar ps1 tar' },
  { name: 'DocumentManagement', types: 'bat bin exe apk wsf jar ps1 tar' },
  { name: 'FileCode', types: 'json xml class cs js css less tsx html htm xhtml asp aspx' },
  { name: 'Slideshow', types: 'avi flv m4v mkv mov mp4 mpg mpeg wmv' }
];

export const bytesToSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const EditTicketAttachments = ({
  isOpen,
  onCLose,
  primaryButtonColor,
  isSaving,
  onInteraction,
  globalTheme,
  isTicketClosed,
  ticketId,
  acceptedFiles,
  rejectedFiles,
  fileTriggerChannel,
  isEndUserPage,
  fileAttachments,
  setAttachmentsCount,
  signedInUser,
  teamsConfig
}: Props) => {
  const {t} = useTranslation();
  const api = new platformService();
  const graphApi = new graphService();
  const [files, setFiles] = useState<FileProps[]>([]);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAttachmentListDisabled, setIsAttachmentListDisabled] = useState(false);

  const showColumns = (isEndUserPage || isTicketClosed) ? '1' : '2';
  const channelNotSet = fileTriggerChannel == null || fileTriggerChannel?.length < 1;
  const uploadDisabled = isTicketClosed || channelNotSet;
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [stepUploadDialog, setStepUploadDialog] = useState<number>(0);

  const classNames = mergeStyleSets({
    fileLink: {
      fontWeight: 600,
      color: globalTheme.siteVariables.colorScheme.brand.foreground1,
      ':hover, :active, :focus': {
        color: 'var(--mgt-theme-brand-foreground1) !important'
      }
    },
    alertLink: {
      color: 'inherit',
      fontWeight: 600,
      ':hover, :active, :focus': {
        color: 'inherit'
      }
    },
    fileIcon: {
      color: globalTheme.siteVariables.colorScheme.default.foreground2,
      marginRight: '.5rem'
    }
  });

  const getFileAttachments = debounce(async () => {
    const filteredAttachments = isEndUserPage ? fileAttachments.filter(a => a["IsPublic"]) : fileAttachments;
    setFiles(filteredAttachments);
  }, 300);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getFileAttachments();
      setIsLoading(false);
    })();
  }, [ticketId, fileAttachments]);

  useEffect(() => {
    if (acceptedFiles.length > 0) onDropAccepted(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (rejectedFiles.length > 0) onDropRejected(rejectedFiles);
  }, [rejectedFiles]);

  useEffect(() => {
    setAttachmentsCount(files.length);
  }, [files]);

  const dropzoneRef = createRef<DropzoneRef>();
  const onDropAccepted = (items: File[]) => {
    
    onCLose(true);
    setUploadFiles(items)

    setStepUploadDialog(1);
    setOpenUploadDialog(true);
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    setFiles((currentItems: FileProps[]) => {
      let newItems: FileProps[] = fileRejections.map(rejected => {
        let item = rejected.file;
        return Object.assign(item, {
          FileName: item.name,
          isUploadFailed: true,
          uploadFailedMessage: `${uploadFailedMessage} ${rejected.errors
            .map(x => x.message)
            .join(' ')}`
        } as FileProps);
      });
      return newItems.concat(currentItems);
    });
  };

  const removeFileInList = (event: any) => {
    const target = event.target as HTMLElement;
    target.closest('li').remove();
  };

  const deleteFileInList = async (id: any) => {
    try {
      setIsAttachmentListDisabled(true);
      const deleteFile = await api.deleteFile(id);
      if (deleteFile?.status == 204){
        setIsAttachmentListDisabled(false);
        setFiles((currentItems: FileProps[]) => {
          return currentItems.filter(item => item.Id != id);
        });
        onInteraction({ event: EditTicketEvent.DeleteSuccess });
      }
    } catch (error) {
      console.error(`There was an error deleting File`);
    }
  };

  const renderSkeleton = (num: number) =>
    Array.from(Array(num)).map((_r, index) => (
      <ListItem
        {...{
          index: index,
          media: (
            <Skeleton animation="wave">
              <Skeleton.Shape width="32px" height="32px"></Skeleton.Shape>
            </Skeleton>
          ),
          content: (
            <Skeleton animation="wave">
              <Skeleton.Line></Skeleton.Line>
            </Skeleton>
          )
        }}
      />
    ));

  const renderFiles = files.map((file, index) => (
    <ListItem
      {...{
        index: index,
        media: file.isUploading ? (
          <Loader size="smallest" inline />
        ) : (
          getFileIcon(file.isUploadFailed ? 'failed' : file.FileExtension, file, globalTheme)
        ),
        header: (
          <Link
            disabled={file.isUploading || file.isUploadFailed || file.MoveItemFailed}
            target="_blank"
            href={file.DownladUrl}
            className={classNames.fileLink}
          >
            {getTruncatedFilename(file.FileName)}
          </Link>
        ),
        headerMedia: file.isUploading ? (
          ''
        ) : file.isUploadFailed ? (
          <Text error content="Failed" />
        ) : (
          // bytesToSize(file.FileSize)
          ("")
        ),
        endMedia: file.isUploading || file.MoveItemFailed ? (
          ''
        ) : file.isUploadFailed ? (
          <Button
            disabled={isTicketClosed}
            icon={<Icon iconName="Delete" className={'text-lg'} />}
            iconOnly
            text
            title={t('ticket-details.edit-ticket-form.attachments.button.remove')}
            onClick={removeFileInList}
          />
        ) : (
          <Grid accessibility={gridHorizontalBehavior} columns={showColumns}>
            {!isEndUserPage && !isTicketClosed && (
              <Dialog
                style={{ maxWidth: "600px" }}
                cancelButton={t('ticket-details.toolbar.action.delete-dialog.button.cancel')}
                confirmButton={t('ticket-details.toolbar.action.delete-dialog.button.confirm')}
                onConfirm={() => {
                  deleteFileInList(file.Id);
                }}
                header={t('ticket-details.edit-ticket-form.attachments.delete-dialog.header')}
                content={getTruncatedFilename(file.FileName, 50)}
                trigger={
                  <Button
                    disabled={isTicketClosed}
                    icon={<Icon iconName="Delete" className={'text-sm'} />}
                    iconOnly
                    text
                    title={t('ticket-details.edit-ticket-form.attachments.button.delete')}
                  />
                }
              />
            )}

            <Button
              icon={<Icon iconName="Download" className={'text-sm'} />}
              iconOnly
              text
              title={t('ticket-details.edit-ticket-form.attachments.button.download')}
              onClick={() =>
                graphApi.downloadFile(file.DriveItemGroupId, file.DriveItemId)
              }
            />
          </Grid>
        ),
        content: file.isUploading ? (
          t('ticket-details.edit-ticket-form.attachments.upload-in-progress')
        ) : file.isUploadFailed || file.MoveItemFailed ? (
          <Text error={file.isUploadFailed ? true : false} content={(file.MoveItemFailed) ? t('ticket-details.edit-ticket-form.attachments.move-to-different-team'): file.uploadFailedMessage} />
        ) : (
          <span style={{ fontSize: '9px', lineHeight: '12px' }}>
            <RelativeDate
              date={file.ModifiedDate}
              text={t('ticket-details.edit-ticket-form.attachments.button.updated')}
              fileSize={bytesToSize(file.FileSize)}
            />
          </span>
        ),
        style: {
          borderBottomWidth: 'thin',
          height: '4rem',
          display: file.isHidden ? 'none' : 'flex',
          cursor: 'auto'
        },
        hidden: file.isHidden
      }}
    />
  ));

  return (
    <>
      <Flex
        vAlign="center"
        space="between"
        className={`mt-7 pb-4 cursor-pointer`}
        style={{
          borderBottom: isOpen ? `1px solid ${primaryButtonColor}` : 'none'
        }}
        onClick={() => {
          onCLose(!isOpen);
        }}
      >
        <Flex gap="gap.small" vAlign="center">
          {isOpen ?  <ChevronEndIcon /> : <ChevronDownIcon />}
          <Flex
            style={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px'
            }}
          >
            <span> {t('ticket-details.edit-ticket-form.attachments.attachments')} ({files.length})</span>
          </Flex>
        </Flex>
        {isOpen && (
          <div className={`md:flex items-center`}>
            {isSaving && (
              <Loader
                label={t('ticket-details.edit-ticket-form.attachments.saving')}
                labelPosition="end"
                className={`mr-2`}
                size="smallest"
              />
            )}
            <Button
              icon={<Icon iconName="Upload" className={'text-sm'} />}
              content={t('ticket-details.edit-ticket-form.attachments.button.upload')}
              className={uploadDisabled ? CSS['upload-btn-disabled'] : CSS['upload-btn-active']}
              text
              disabled={uploadDisabled}
              onClick={e => {
                e.stopPropagation();
                if (dropzoneRef.current) dropzoneRef.current.open();
              }}
            />
          </div>
        )}
      </Flex>
      {isOpen && (
        <>
          {isLoading && (
            <>
              <Skeleton animation="wave">
                <Flex
                  space="between"
                  style={{ margin: '1.25rem 0', alignItems: 'center' }}
                >
                  <Flex.Item className="extended-toolbar__far-side">
                    <Flex vAlign="center">
                      <Skeleton.Button />
                    </Flex>
                  </Flex.Item>
                  <Flex.Item>
                    <Skeleton.Input />
                  </Flex.Item>
                </Flex>
              </Skeleton>
              <List>{renderSkeleton(10)}</List>
            </>
          )}
          {!isLoading && (
            <>
              {isTicketClosed ? (
                <>
                  <Flex.Item>
                    <Alert
                      danger
                      icon={<ExclamationTriangleIcon />}
                      content={
                        <Text content={t('ticket-details.edit-ticket-form.attachments.closed-ticket-message')} />
                      }
                    />
                  </Flex.Item>
                </>
              ) : (
                <>
                  {channelNotSet && (
                    <>
                      {!isEndUserPage && (
                        <Flex.Item>
                          <Alert
                            danger
                            icon={<ExclamationTriangleIcon />}
                            content={
                              <>
                                <Text content={t('ticket-details.edit-ticket-form.attachments.upload-disabled-message')} />
                                <Link
                                  target="_blank"
                                  href="https://help.tikit.ai/faq/how-do-i-add-tikit-to-a-team/"
                                  className={classNames.alertLink}
                                >
                                  {t('ticket-details.edit-ticket-form.attachments.learn-more')}
                                </Link>
                              </>
                            }
                          />
                        </Flex.Item>
                      )}
                      {isEndUserPage && (
                        <Flex.Item>
                          <Alert
                            danger
                            icon={<ExclamationTriangleIcon />}
                            content={
                              <Text content={t('ticket-details.edit-ticket-form.attachments.upload-disabled')} />
                            }
                          />
                        </Flex.Item>
                      )}
                    </>
                  )}
                </>
              )}
              <Flex.Item grow>
                <Dropzone
                  ref={dropzoneRef}
                  noClick
                  noKeyboard
                  onDropAccepted={onDropAccepted}
                  onDropRejected={onDropRejected}
                  disabled={uploadDisabled}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <div
                        style={{
                          maxHeight: 'calc(80vh - 320px)',
                          overflow: 'auto',
                          display: 'grid'
                        }}
                      >
                        <div
                          {...getRootProps({
                            className: 'dropzone overflow-auto'
                          })}
                          style={
                            isAttachmentListDisabled
                              ? { pointerEvents: 'none', opacity: '0.7' }
                              : {}
                          }
                        >
                          <input {...getInputProps()} />
                          {isDragActive && !isTicketClosed && (
                            <Flex
                              style={{
                                height: 'calc(100% - 227px)',
                                width: 'calc(100% - 2.9rem)',
                                backgroundColor:
                                  globalTheme.siteVariables.colorScheme.default
                                    .background,
                                opacity: '0.5',
                                zIndex: 2,
                                cursor: 'pointer',
                                position: 'absolute',
                                justifyContent: 'center'
                              }}
                            >
                              {files.length > 0 && (
                                <Text
                                  content={t('ticket-details.edit-ticket-form.attachments.drop-file-here')}
                                  size="largest"
                                  weight="bold"
                                  style={{ alignSelf: 'center' }}
                                />
                              )}
                            </Flex>
                          )}
                          {files.length > 0 && (
                            <List
                              // navigable
                              truncateHeader
                              variables={{selectableFocusHoverBackgroundColor: globalTheme.siteVariables.colorScheme.default.background}}
                            >
                              {renderFiles}
                            </List>
                          )}
                          {files.length == 0 && (
                            <div className={`py-10`}>
                              <EmptyData
                                headerText={t('ticket-details.edit-ticket-form.attachments.empty.header-text')}
                                subheaderText={!uploadDisabled && t('ticket-details.edit-ticket-form.attachments.empty.sub-header-text')}
                                SVGIcon={<EmptySVG height={70} width={70} />}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </Flex.Item>
            </>
          )}
        </>
      )}
      <Dialog
        closeOnOutsideClick={false}
        open={openUploadDialog}
        content={<NewAttachmentFlowContent globalTheme={globalTheme} ticketId={ticketId} currentFiles={files} setCurrentFiles={setFiles} filesToUpload={uploadFiles} onInteraction={onInteraction} teamsConfig={teamsConfig} isEndUserPage={isEndUserPage} onClose={() => setOpenUploadDialog(false)} />}
        header={`${t('ticket-details.edit-ticket-form.attachments.add-attachment-to-ticket')} #${ticketId}`}
        headerAction={{
          icon: <CloseIcon />,
          title: t('common.buttons.close'),
          onClick: () => setOpenUploadDialog(false)
        }}
        style={{ minWidth: '650px', maxWidth: '700px' }}
      />
    </>
  );
};
