import React from 'react';
import { Breadcrumb, ChevronEndIcon, Text } from '@fluentui/react-northstar';
import { Link } from 'react-router-dom';

export interface BreadcrumbProperties {
  title: string;
  link: string;
}

export interface Breadcrumbs {
  breadcrumbs: BreadcrumbProperties[];
}

export const BreadcrumbNavigation = (props: Breadcrumbs) => {
  return (
    <Breadcrumb aria-label="breadcrumb">
      {props.breadcrumbs.map(function (item, index) {
        return (
          <>
            {props.breadcrumbs.length != 1 &&
              props.breadcrumbs.length != index + 1 && (
                <>
                  <Breadcrumb.Item>
                    <Link to={item.link}>{item.title}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Divider>
                    <Text content={<ChevronEndIcon />} />
                  </Breadcrumb.Divider>
                </>
              )}
            {props.breadcrumbs.length == index + 1 && (
              <Breadcrumb.Item aria-current="page">
                <Text content={item.title} weight={'bold'} />
              </Breadcrumb.Item>
            )}
          </>
        );
      })}
    </Breadcrumb>
  );
};
