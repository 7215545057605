import React, { useEffect, useState, useContext } from 'react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import {
  ApprovalListIcon,
  TaskListIcon,
  TicketListIcon,
} from '../../../../svg';
import EmptySVG from './../../../../svg/empty.svg';
import { MyWorkListDataI } from '../MyWork';
import { ThemeColorScheme } from '../../../shared/common/TeamsTheme';
import {
  SkeletonCell, hierarchialCategoryName, hierarchialPriorityName, hierarchialStatusName, hierarchialTicketType,
} from '../../../shared/components/table/TicketListBoard';
import { LinkRecord, SLAPills, _getUser, _getUserAvatar, _getUsers } from '../../../shared/components/table/ClassDetailList';
import { appState } from '../../../AppState';
import { ConvertDateToLocale } from '../../../shared/common/ConvertDate';
import { TicketLifecyclePhaseElement } from '../../../shared/components/TicketLifecycleTab/TicketLifecyclePhaseRibbon';
import '../myWork.css'
import { EnumSortOrderType } from '../../toolbar/CustomViewContextProvider';
import { MyWorkContext } from '../MyWorkContextProvider';
import useMyWorkListFunctions from './useMyWorkListFunctions';
import { getSelectedTabFromStorage, getMyWorkSortOrderFromStorage, setMyWorkSortOrderInStorage } from './myWorkListHelper';
import { ListColumnKey, MyWorkListViewType } from '../../../shared/utils/constants';
import { Route } from '../../../../route-constants';
import { useLocation } from 'react-router-dom';
import { EmptyData } from '../../../shared/components/EmptyData';
import { DateTime } from 'luxon';
import { i18n } from '../../../shared/utils/helper';
import { PlatformUser } from '../../../shared/interfaces/platformuser.interface';

const { t } = i18n();

export interface IDetailsListBasicExampleItem {
  key: number;
  name: string;
  value: number;
}

export interface IDetailsListBasicExampleState {
  items: IDetailsListBasicExampleItem[];
  selectionDetails: string;
}

const renderRecord = (
  item: MyWorkListDataI
): { [x: string]: React.ReactElement } => {
return {
  Id: <LinkRecord {...{ticketId: item.TicketId, itemId: item.Id, value: item.TicketId, type: item.ItemType, title: item.TicketTitle }} />,
  Title: <LinkRecord {...{ticketId: item.TicketId, itemId: item.Id, value: item.Title, type: item.ItemType, title: item.Title }} />,
  Type: (
    <div className={`w-full truncate mr-4`} dir='rtl'>
      <bdi>{item.TicketType || '-'}</bdi>
    </div>
  ),
  SLAs: item.TicketSlas?.length > 0 ? <SLAPills ticketSlas={item.TicketSlas} /> : <>-</>,
  Phase: (<>{item.LifecyclePhaseName ? (<TicketLifecyclePhaseElement status={item.LifeCycleStatus} phaseName={item.LifecyclePhaseName} />) : '-'}</>),
  Requester: item.Requester.length > 1  ? _getUsers(item.Requester.map(r => ({ PlatformUserId: r.Id }))) : 
             item.Requester.length == 1 ? _getUser(item.Requester[0]?.AadObjectId ?? item.Requester[0]?.UserName, item.Requester[0]?.FullName) : <>-</>,
  Assignee: item.Assignee.length > 1  ? _getUsers(item.Assignee.map(r => ({ PlatformUserId: r.Id }))) : 
            item.Assignee.length == 1 ? _getUser(item.Assignee[0]?.AadObjectId ?? item.Assignee[0]?.UserName, item.Assignee[0]?.FullName) : <>-</>,
  'Last Modified Date': (<span>{ConvertDateToLocale(item.LastModified, DateTime.DATE_SHORT)}</span>),
  'Created Date': <span>{ConvertDateToLocale(item.CreatedDate, DateTime.DATE_SHORT)}</span>,
  'Due Date': <span>{item.DueDate ? ConvertDateToLocale(getDateOrEmptyString(item.DueDate), DateTime.DATE_SHORT): '-'}</span>,
  'Resolution Date': <span>{item.ResolutionDate ? ConvertDateToLocale(getDateOrEmptyString(item.ResolutionDate), DateTime.DATE_SHORT): '-'}</span>,
}};

const getDateOrEmptyString = (date: string | null) => date ? date : '';

const renderRecordValue = (colName: string) => (item: MyWorkListDataI) => {
  return item.Title === 'temp_ticket' ? (
    <SkeletonCell />
  ) : (
    renderRecord(item)[colName]
  );
};

// name: [ key, name, fieldName, minWidth, maxWidth, isMultiline, isPadded, onRender ]
const _requestLength = (localStorage.getItem('TikitRequestLength') || 'abbreviated') == 'full';
const _cols: _ColsType = {
  Icon: [ListColumnKey.Icon, '', 'Icon', 10, 10, null, null, null],
  Id: [ListColumnKey.Id, t('ticket.ticket-list.id'), 'Id', 30, 30, null, true, renderRecordValue('Id')],
  Type: [ListColumnKey.Type, t('ticket.ticket-list.type'), 'TicketType', 75, 125, true, true, renderRecordValue('Type')],
  Title: [ListColumnKey.Title, t('ticket.ticket-list.title'), 'Title', (_requestLength ? 100 : 200), (_requestLength ? 200 : 400), (_requestLength ? true : null), true, renderRecordValue('Title')],
  StatusName: [ListColumnKey.Status, t('ticket.ticket-list.status'), 'StatusName', 100, 200, null, null, null],
  Requester: [ListColumnKey.Requester, t('ticket.ticket-list.requester'), 'Requester', 100, 200, null, null, renderRecordValue('Requester')],
  Category: [ListColumnKey.Category, t('ticket.ticket-list.category'), 'Category', 100, 200, null, null, null],
  Priority: [ListColumnKey.Priority, t('ticket.ticket-list.priority'), 'Priority', 100, 200, null, null, null],
  SLAs: [ListColumnKey.SLA, t('ticket.ticket-list.sla'), 'SLA', 100, 220, null, null, renderRecordValue('SLAs')],
  Team: [ListColumnKey.Team, t('ticket.ticket-list.team'), 'TeamName', 100, 200, null, null, null],
  Group: [ListColumnKey.Group, t('ticket.ticket-list.group'), 'GroupName', 100, 200, null, null, null],
  Assignee: [ListColumnKey.Assignee, t('ticket.ticket-list.assignee'), 'Assignee', 100, 200, null, true, renderRecordValue('Assignee')],
  Lifecycle: [ListColumnKey.Lifecycle, t('ticket.ticket-list.lifecycle'), 'LifecycleName', 100, 200, null, null, null],
  Phase: [ListColumnKey.Phase, t('ticket.ticket-list.phase'), 'LifecyclePhaseName', 100, 200, null, null, renderRecordValue('Phase')],
  'Last Modified Date': [ListColumnKey.LastModifiedDate, t('ticket.ticket-list.last-modified-date'), 'LastModified', 100, 200, null, null, renderRecordValue('Last Modified Date')],
  'Created Date': [ListColumnKey.CreatedDate, t('ticket.ticket-list.created-date'), 'CreatedDate', 100, 200, null, null, renderRecordValue('Created Date')],
  'Due Date': [ListColumnKey.DueDate, t('ticket.ticket-list.due-date'), 'DueDate', 100, 200, null, null, renderRecordValue('Due Date')],
  'Resolution Date': [ListColumnKey.ResolutionDate, t('ticket.ticket-list.resolution-date'), 'ResolutionDate', 100, 200, null, null, renderRecordValue('Resolution Date')],
};

const IconObj: {
  [key: string]: React.SVGAttributes<SVGElement>;
} = {
  [MyWorkListViewType.Tickets]: <TicketListIcon />,
  [MyWorkListViewType.Tasks]: <TaskListIcon width={16} height={16}/>,
  [MyWorkListViewType.Approvals]: <ApprovalListIcon />,
};

const _onColumnClick = (
  onColumnClickCallback: (column: IColumn) => void
) => (
  ev: React.MouseEvent<HTMLElement>,
  column: IColumn
): void => {
  const NON_SORTABLE_COLUMNS = [ListColumnKey.SLA, ListColumnKey.Assignee];
  if(NON_SORTABLE_COLUMNS.some(x => Number(x) == Number(column.key))) return;

  onColumnClickCallback(column);
};

const _columns = (
  myWorkStates: MyWorkStatesType,
  onColumnClickCallback: (column: IColumn) => void,
  isMyGroupWork: boolean,
): IColumn[] => {
  const selectedTabId = getSelectedTabFromStorage(isMyGroupWork) ?? myWorkStates.myWorkList.selectedTabId;
  
  const sortOrder = getMyWorkSortOrderFromStorage(isMyGroupWork, selectedTabId);
  const orderBy = sortOrder?.orderBy || myWorkStates.sortOrder?.[selectedTabId]?.orderBy;
  const orderType = sortOrder?.orderType || myWorkStates.sortOrder?.[selectedTabId]?.orderType;

  return Object.values(_cols).map((value) => {
    return {
      key: value[0] + '',
      name: value[1],
      fieldName: value[2],
      minWidth: value[3],
      maxWidth: value[4],
      isMultiline: value[5],
      isPadded: value[6],
      ...(value[7] && { onRender: value[7] }),
      isSorted: value[0] ===  orderBy ? true : null,
      isSortedDescending: value[0] === orderBy && orderType === EnumSortOrderType.desc ? true : false,
      onColumnClick: _onColumnClick(onColumnClickCallback),
      isResizable: true,
      isCollapsible: true,
    };
  });
};

const noDataFoundObj = {
  [MyWorkListViewType.All]: t('my-work.my-ticket.no-data-all'),
  [MyWorkListViewType.Tickets]: t('my-work.my-ticket.no-ticket'),
  [MyWorkListViewType.Tasks]: t('my-work.my-ticket.no-task'),
  [MyWorkListViewType.Approvals]: t('my-work.my-ticket.no-approval'),
}

const DetailsListTable = () => {
  const [selection, setSelection] = useState<Selection | undefined>();
  function _getSelectionDetails(): string {
    const selectionCount = selection ? selection.getSelectedCount() : 0;

    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return (
          '1 item selected: ' +
          (selection.getSelection()[0] as IDetailsListBasicExampleItem).name
        );
      default:
        return `${selectionCount} items selected`;
    }
  }
  const currentstate = appState();
  const { myWorkStates, setMyWorkStates } = useContext(MyWorkContext);
  const { getpaginatedMyWorkList } = useMyWorkListFunctions();
  const [state, setState] = useState({
    items: myWorkStates.myWorkList.allData,
    selectionDetails: _getSelectionDetails(),
  });
  const location = useLocation();

  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);

  const [columns, setColumns] = useState([] as IColumn[]);

  const onColumnClickCallback = (column: IColumn) => {
    const sortOrder = {
      orderBy: Number(column.key) as ISortOrderBy,
      orderType: column.isSortedDescending ? EnumSortOrderType.asc : EnumSortOrderType.desc,
    } as ISortOrder;
    
    const selectedTabId = getSelectedTabFromStorage(isMyGroupWork) ?? myWorkStates.myWorkList.selectedTabId;
    
    setMyWorkSortOrderInStorage(isMyGroupWork, selectedTabId, sortOrder);
    setMyWorkStates((prev) => ({
      ...prev,
      sortOrder: {
        ...prev.sortOrder,
        [selectedTabId]: sortOrder,
      },
      myWorkList: {
        ...prev.myWorkList,
        currentPage: 1
      }
    }));

    getpaginatedMyWorkList({ sortOrder, page: 1, selectedTabNumber: selectedTabId });
  }

  useEffect(() => {
    const _selection: Selection = new Selection({
      onSelectionChanged: () =>
        setState((prev) => {
          return { ...prev, selectionDetails: _getSelectionDetails() };
        }),
    });
    setSelection(_selection);

    setColumns(_columns(myWorkStates, onColumnClickCallback, isMyGroupWork));
  }, []);

  useEffect(() => {
    if([MyWorkListViewType.Tasks, MyWorkListViewType.Approvals].includes(getSelectedTabFromStorage(isMyGroupWork) || myWorkStates.myWorkList.selectedTabId)) {
      setColumns((prev) => {
        const copiedColumns = prev.slice();
        const typeColumnIdx = copiedColumns.findIndex(column => Number(column.key) === Number(ListColumnKey.Type));
        copiedColumns.splice(typeColumnIdx, 1);

        return copiedColumns;
      })
    }

    const myWorkListdata = [...(myWorkStates.myWorkList.allData || [])];
    const workList = myWorkListdata.map((item) => {
      const requesters = [];
      if (item.RequesterId)
        requesters.push(currentstate.platformusers.find(x => x.Id == item.RequesterId));
      if (item.AffectedUsers?.length > 0)
        requesters.push(...item.AffectedUsers.map(a => currentstate.platformusers.find(x => x.Id == a)));
      
      const assignee = [...item.Assignee.map(a => currentstate.platformusers.find(x => x.Id == a))];
      if (item.Collaborators?.length > 0)
        assignee.push(...item.Collaborators.map(a => currentstate.platformusers.find(x => x.Id == a)));

      return {
        ...item,
        Icon: IconObj[item.ItemType],
        StatusName: item.ItemType == MyWorkListViewType.Tickets ? hierarchialStatusName({ statusId: item.StatusId, ticketStatuses: currentstate.ticketStatus }) : item.StatusName,
        Requester: requesters.filter(x => x),
        Assignee: assignee.filter(x => x), 
        TeamName: item['TeamId'] ? item.TeamName : '-',
        GroupName: item['SupportGroupId'] ? item.SupportGroupName : '-',
        TicketType: item.ItemType === MyWorkListViewType.Approvals ? t('ticket-details.edit-ticket-form.approval.approval') :
                    item.ItemType === MyWorkListViewType.Tasks     ? t('ticket-details.edit-ticket-form.task.task') : 
                    item.TypeId                                    ? hierarchialTicketType({ ticketTypeId: item.TypeId, ticketTypes: currentstate.ticketTypes }) : item.TypeName ?? '-',
        Category: item.CategoryId ? hierarchialCategoryName({ categoryId: item.CategoryId, categories: currentstate.categories }) : '-',
        Priority: item.PriorityId ? hierarchialPriorityName({ priorityId: item.PriorityId, priorities: currentstate.priority }) : '-',
        TicketLifecycleName: item.TicketLifecycle?.Title || '-',
        LifeCycleStatus: { Guid: item.LifecycleStatusId }
      };
    });
    setState((prev) => {
      return { ...prev, items: workList };
    });
  }, [myWorkStates.myWorkList]);

  if((myWorkStates.myWorkList?.allData?.length ?? 0) <= 0){
    return <EmptyData
      headerText={noDataFoundObj[getSelectedTabFromStorage(isMyGroupWork) || myWorkStates.myWorkList.selectedTabId]}
      SVGIcon={<EmptySVG height={200} width={200} />}
    />
  }

  return selection ? (
    <FluentUIThemeConsumer
      render={(globalTheme) => (
        <div
          className={`py-3.5 px-2.5 min-h-32 main-ticket-list`}
          style={{
            backgroundColor: 'var(--mgt-theme-background2)',
            ...ThemeColorScheme(globalTheme.siteVariables),
          }}
        >
          <div>
            <MarqueeSelection selection={selection}>
              <DetailsList
                items={state.items}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey='multiple'
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn='Toggle selection'
                ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                checkButtonAriaLabel='select row'
                className="details-list-table"
              />
            </MarqueeSelection>
          </div>
        </div>
      )}
    />
  ) : (
    <></>
  );
};

export default DetailsListTable;

type ColValuesType = [
  number,
  string,
  string,
  number,
  number,
  boolean | null,
  boolean | null,
  (item: MyWorkListDataI) => JSX.Element
];
type _ColsType = {
  [key: string]: ColValuesType;
};
