import { Datepicker, Dropdown, Text, TextArea, ProviderConsumer as FluentUIThemeConsumer, Flex } from '@fluentui/react-northstar';
import { PeoplePicker } from '@microsoft/mgt-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppStateContext, appState, useSetState } from '../../AppState';
import { platformService } from '../../shared/services/platform.service';
import MgtCss from '../../mgt.module.css';
import AppCSS from '../../App.module.css';
import { PlatformUser } from '../interfaces/platformuser.interface';
import { ThemeColorScheme } from '../common/TeamsTheme';
import { ConvertDateToLocale } from '../common/ConvertDate';
import { NestedDropdown } from './NestedDropdown/NestedDropdown';
import { FILTERS_STRINGS, LICENSE_FEATURE, STATUS_GUID, TAGS } from '../utils/constants';
import { CheckFeature } from './CheckFeature';
import { UserType, PersonType } from '@microsoft/mgt-components';
import { TicketTagPicker } from '../common/Controls/TagsPicker';
import { getCachedFeature } from '../cache/FeatureCache';
import { filterPlatformUsersInRole, getPortalSource, getStatusIdByGuid } from '../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import { DatepickerLocalizations } from '../../App';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

type DropdownProps = {
  name?: string;
  items: any;
  item?: any;
  value?: any;
  handler?: any;
  placeholder?: string;
  properties?: {};
  disabled?: boolean;
  isClearable?: boolean;
  header?: string;
  collaboratorIds?: number[]
};

export interface LooseObject {
  [key: string]: any;
}

type TicketFormStateProps = {
  data: TicketEntityProps;
  ticketStatus: any[];
  categories: any[];
  ticketTypes: any[];
  priority: any[];
  usersInRole: any[];
  supportgroups: any[];
  teams: any[];
  actionType: ActionTypes;
  dispatch: React.Dispatch<any>;
  defaultTeam: any;
  defaultSupportGroup: any;
};

export enum ActionTypes {
  Create,
  Edit
}

export interface TicketEntity {
  Title: string;
  RequesterId?: number;
  AssigneeId?: number;
  StatusId?: number;
  CategoryId?: number;
  TicketTypeId?: number;
  PriorityId?: number;
  DueDate?: Date;
  ResolutionDate?: Date;
  SupportGroupId?: number;
  TeamId?: number;
  Tags?: number[];
  Collaborators?: any[];
  AffectedUsers?: any[];
  SourceId?: number;
  DefaultTeamId?: number;
  DefaultSupportGroupId?: number;
}

export interface TicketEntityProps extends LooseObject {
  Entity: TicketEntity;
  Requester: any;
  SelectedTemplate:any;
  SelectedEnums:{
    StatusId:any;
    CategoryId:any;
    PriorityId:any;
    TicketTypeId:any;
  }
  ApplyTemplateNow: boolean;
  ResetForm: boolean;
  TemplateHasError: boolean;
  TemplateErrorMessage: string;
  LifecycleId:any;
}

export interface TicketFormProps {
  type: ActionTypes;
  data?: TicketEntityProps;
  dispatch: React.Dispatch<any>;
}

export const TicketActions = {
  Id: "Id",
  ClearData: "ClearData",
  StatusId: "StatusId",
  CategoryId: "CategoryId",
  PriorityId: "PriorityId",
  Requester: "Requester",
  TicketTypeId: "TicketTypeId",
  TeamId: "TeamId",
  SupportGroupId: "SupportGroupId",
  AssigneeId: "AssigneeId",
  DueDate: "DueDate",
  RelativeDueDate: "RelativeDueDate",
  Tags: "Tags",
  LifecycleId: "LifecycleId",
  TicketLifecycleId: "TicketLifecycleId",
  SelectedEnums: "SelectedEnums",
  ApplyTemplateNow: "ApplyTemplateNow",
  TemplateId: "TemplateId",
  CardTemplateJson: "CardTemplateJson",
  CardResponseJson: "CardResponseJson",
  CardAnswerJson:"CardAnswerJson",
  ResetForm: "ResetForm",
  AffectedUsers: "AffectedUsers",
  Collaborators: "Collaborators"
}

export const entityTicketModel = () => {
  return {
    Entity: { Title: "" },
    Requester: null,
    SelectedTemplate: {
      NoCustomForm: false,
      ClearFormAnswer: false
    },
    SelectedEnums: {
      StatusId: null,
      CategoryId: null,
      PriorityId: null
    },
    ApplyTemplateNow: false,
    ResetForm: false,
    TemplateHasError: false,
    TemplateErrorMessage: "",
    LifecycleId: null
  } as TicketEntityProps;
};

export const entityTicketReducer = (state, action) => {
  switch (action.action) {
    case TicketActions.ClearData:
      return {
        ...state,
        ...entityTicketModel()
      };
    case TicketActions.SelectedEnums:
      if (action.data) {
        state.SelectedEnums[action.property] = action.data;
        state.Entity[action.property] = action.data.Id;
      }
      return {
        ...state
      };
    default:
      if (action.data) {
        if (action.isEntity) {
          state.Entity[action.action] = action.data;
        }
        else state[action.action] = action.data;
      } else {
        if (action.isEntity) {
          state.Entity[action.action] = (action.data == null && action.action.toLowerCase().includes("id")) ? null : "";
        } else {
          state[action.action] = action.data;
        }
      }

      return {
        ...state
      };

  }

}

export const getBackgroundColor = ({ siteVariables: { colorScheme } }: any, disabled?: boolean) => {
  return (disabled) ? colorScheme.default.backgroundDisabled1 : colorScheme.default.background2;
}

const api = new platformService();

export const setPlatformUser = (user: any): PlatformUser => {
  return {
    FullName: user.displayName,
    GivenName: user.givenName,
    FamilyName: user.surname,
    Email: user.mail ?? user.userPrincipalName,
    UserName: user.userPrincipalName,
    AadObjectId: user.id ?? null
  } as PlatformUser;
};

export const getTagsList = async (): Promise<any[]> => {
  try {
    const enabledTicketTags = await getCachedFeature(LICENSE_FEATURE.TicketTags);
    if (enabledTicketTags) {
      const tagresults = await api.getTags(FILTERS_STRINGS.DELETE_FALSE);
      return tagresults;
    } else {
      return [];
    }
  } catch (err) {
    console.log("Error while get tags list", err)
  }
};

export const getDefaultTeamAndSG = async (): Promise<any> => {
  try {
    let teamId = 0;
    let supportGroupId = 0;
    let defaultTeam = await getTeamByFilter(`IsDefault eq true`);
    teamId = defaultTeam.Id;
    let defaultSupportGroup = await getSupportGroupByFilter(`TeamsChannelId eq '${defaultTeam.TeamsChannelId}'`);
    supportGroupId = defaultSupportGroup.Id;

    return { teamId: teamId, supportGroupId: supportGroupId }
  } catch (err) {
    console.log("Error while get default team and support group", err)
  }
}

export const createTag = async (data: any) => {
  try {
    const enabledTicketTags = await getCachedFeature(LICENSE_FEATURE.TicketTags);
    if(enabledTicketTags) {
      const getTags = await api.getTags(`?$filter=Name eq '${encodeURIComponent(data.Name.replace(/'/ig, "''"))}'`);
      if (getTags.length == 0) {
        const addTagResponse = await api.addTag(data);
        return addTagResponse.data;
      } else {
        throw new Error('duplicate');
      }
    }
  } catch (err) {
    return err;
  }
};

export const collaboratorsFormat = (analystList: any[], assigeeId?: null | number, selected?: null | number[]) => {
  let itemCollaborator: any = [];
  analystList.forEach((t: { FullName: any; Id: number }) => {
    if(t.Id !== assigeeId) {
      let currentCollaborator: LooseObject = {};
      currentCollaborator = {};
      currentCollaborator['header'] = t.FullName;
      currentCollaborator['accessibilityitemprops'] = t.Id;
      currentCollaborator['selected'] = selected?.some(r => r == t.Id) ?? false;
      itemCollaborator.push(currentCollaborator);
    }
  });

  return itemCollaborator;
}

const TicketForm: React.FC<TicketFormProps> = props => {
  let { type, data, dispatch } = props;

  return (
    <AppStateContext.Consumer>
      {state => {
        return (
          <TicketFormFluentTheme
            data={data}
            actionType={type}
            ticketStatus={state.ticketStatus}
            categories={state.categories}
            ticketTypes={state.ticketTypes}
            priority={state.priority}
            supportgroups={state.supportgroups}
            teams={state.teams}
            defaultTeam={state.defaultTeam}
            usersInRole={state.usersInRole}
            dispatch={dispatch}
            defaultSupportGroup={state.defaultSupportGroup}
          />
        );
      }}
    </AppStateContext.Consumer>
  );
};

const TicketFormFluentTheme: React.FC<TicketFormStateProps> = props => {
  const {
    ticketStatus,
    categories,
    ticketTypes,
    priority,
    usersInRole,
    data,
    actionType,
    supportgroups,
    teams,
    dispatch,
    defaultTeam,
    defaultSupportGroup
  } = props;
  const [filteredUsers, setFilteredUsers] = useState(usersInRole);
  const [filteredGroups, setFilteredGroups] = useState(supportgroups);
  const [tagList, setTagList] = useState([]);
  const [tagSearchQuery, setTagSearchQuery] = useState<string>('');
  const [isCreatingTag, setIsCreatingTag] = useState<boolean>(false);
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [assigneeFullName, setAssigneeFullName] = useState('');
  const tagSearch = useRef(null);
  const currentState = appState();
  const setAppState = useSetState();
  const [searchTeam, setSearchTeam] = useState("");

  const onChangeTeamQuery = useCallback((searchQuery: string) => {
    setSearchTeam(searchQuery);
  }, [searchTeam]);
  
  const { t } = useTranslation();
  useEffect(() => {
    const tagsPromises = getTagsList();
    tagsPromises.then(taglist => {
      setTagList(taglist);
      setAppState((prev) => ({ ...prev, tags: taglist }));
    });

    const collabs = usersInRole.map(c => ({ header: c.FullName, accessibilityitemprops: c.Id, selected: false }));
    setCollaboratorsList(collabs);

    assignDefaultValues();
    
  }, []);

  useEffect(() => {
    if (data.ResetForm) {
      setSearchTeam("");
      dispatch({ action: TicketActions.ResetForm, data: true });
      assignDefaultValues();
      tagList.forEach(tag => {
        tag.selected = false;
      });
      const clonedList = cloneDeep(tagList);
      setTagList(clonedList);

      dispatch({action:TicketActions.AssigneeId, isEntity:true, data: 0});
    }
  }, [data.ResetForm]);

  useEffect(() => {
    //This will be triggered when applying a template.
    if (data.ApplyTemplateNow) {
      let formattedNewTags = [];
      tagList.forEach(tag => {
        tag.selected = false;
        if (data.SelectedTemplate.Tags.some(x => x.Id == tag.accessibilityitemprops)) {
          tag.selected = true;
          formattedNewTags.push(tag.accessibilityitemprops);
        }
      });
      const clonedList = cloneDeep(tagList);
      setTagList(clonedList);

      if (data.Entity.TeamId) {
        const team = teams.find(x => x.Id === data.Entity.TeamId);
        setSearchTeam(team.FriendlyName);
        setFilteredGroups(supportgroups.filter((x) => x.TeamsAadObjectId === team.TeamsAadObjectId));
        setFilteredUsers(filterPlatformUsersInRole(supportgroups.find(s => s.Id == data.SelectedTemplate.SupportGroupId)?.Members ?? teams.find((x) => x.Id === team.Id)?.Users ?? [], usersInRole));
      } else {
        setFilteredGroups(supportgroups);
        setFilteredUsers(usersInRole);
      }
    
      const setAssignee = usersInRole.find(x => x.Id == data.Entity.AssigneeId);
      if(setAssignee) setAssigneeFullName(setAssignee?.FullName);

      dispatch({ action: TicketActions.Tags, isEntity: true, data: formattedNewTags });
      dispatch({ action: TicketActions.ApplyTemplateNow, data: false });
    }
  }, [data.ApplyTemplateNow]);

  useEffect(() => {
    const thisUsers = filterPlatformUsersInRole(supportgroups.find(s => s.Id == defaultSupportGroup?.Id)?.Members ?? teams.filter((x) => x.Id === defaultTeam?.Id)[0]?.Users ?? [], props.usersInRole);
    setFilteredUsers(thisUsers);
  }, [props.usersInRole]);

  useEffect(() => {
    if (defaultTeam) setFilteredGroups(supportgroups.filter((x) => x.TeamsAadObjectId === defaultTeam.TeamsAadObjectId));
  }, [props.supportgroups]);

  useEffect(() => {
    const collabs = collaboratorsFormat(usersInRole, data.Entity.AssigneeId, data.Entity.Collaborators)
    setCollaboratorsList(collabs);
  }, [usersInRole, data.Entity.AssigneeId, data.Entity.Collaborators]);

  const customFormatter = (date: any) => ConvertDateToLocale(date, DateTime.DATE_SHORT);

  const assignDefaultValues = () => {

    dispatch({ action: TicketActions.ClearData });

    dispatch({
      action: TicketActions.SelectedEnums,
      property: TicketActions.StatusId,
      data: defaultItem(ticketStatus, (d, i) => d.IsDefault === true)
    });

    dispatch({
      action: TicketActions.SelectedEnums,
      property: TicketActions.CategoryId,
      data: defaultItem(categories, (d, i) => d.IsDefault === true)
    });

    dispatch({
      action: TicketActions.SelectedEnums,
      property: TicketActions.PriorityId,
      data: defaultItem(priority, (d, i) => d.IsDefault === true)
    });

    setSearchTeam(defaultTeam?.FriendlyName);
    dispatch({ action: TicketActions.TeamId, isEntity: true, data: defaultTeam?.Id });
    if (defaultTeam && defaultTeam.Id && !defaultTeam.TeamsAadObjectId) {
      setFilteredGroups([]);
      setFilteredUsers([]);
    } else {
      if (defaultTeam && defaultTeam.Id != null) {
        const filteredGroups = supportgroups.filter((x) => x.TeamsAadObjectId === defaultTeam.TeamsAadObjectId);
        const group = (filteredGroups.length > 0) ? filteredGroups.find(x => x.TeamsChannelId == defaultTeam.TeamsChannelId) : null;

        setFilteredGroups(filteredGroups);
        const thisUsers = filterPlatformUsersInRole(supportgroups.find(s => s.Id == group?.Id)?.Members ?? teams.find((x) => x.Id === defaultTeam.Id)?.Users ?? [], usersInRole);
        setFilteredUsers(thisUsers);
      } else {
        setFilteredGroups(supportgroups);
        setFilteredUsers(usersInRole);
      }
    }
    setAssigneeFullName('');

    dispatch({
      action: TicketActions.SelectedEnums, 
      property:TicketActions.TicketTypeId,
      data: defaultItem(ticketTypes, (d, i) => d.IsDefault === true)
    });
    dispatch({ action: TicketActions.SupportGroupId, isEntity: true, data: defaultSupportGroup?.Id });
  }

  const isTagNameEmpty = () => {
    return (
      tagSearchQuery === null ||
      tagSearchQuery === '' ||
      tagSearchQuery.trim() === ''
    );
  };

  const addNewTag = () => {
    setIsCreatingTag(true);
    createTag({ Name: tagSearchQuery }).then(
      response => {
        tagSearch.current.value = '';
        const newTag = {
          header: tagSearchQuery,
          accessibilityitemprops: response.Id,
          selected: false
        };
        setTagList([...tagList, newTag]);
        setIsCreatingTag(false);
        setAppState(pre => ({ ...pre, tags: [...pre.tags, response] }));
      }
    );
  }

  const selectTag = (e, p, key) => {
    const stringifyTheValue = JSON.stringify(p.value);
    const parsedValue: any[] = JSON.parse(stringifyTheValue);
    let formattedNewTags = [];
    parsedValue.forEach(item => {
      const tagId = item['accessibilityitemprops'];
      item.selected = true;
      formattedNewTags.push(tagId);
    });

    tagList.forEach(tag => {
      tag.selected = formattedNewTags.includes(tag.accessibilityitemprops);
    });

    const clonedList = cloneDeep(tagList);
    setTagList(clonedList);
    dispatch({ action: TicketActions.Tags, isEntity: true, data: formattedNewTags });
  }

  const tagOnSearchQueryChange = (e, p) => {
    setTagSearchQuery(p.searchQuery);
  }

  const changeRequester = (e: any) => {
    let requester: any = null;
    let selectedRequested = [];
    if (e.detail.length > 0) {
      selectedRequested.push(e.detail[0]);
      requester = setPlatformUser(e.detail[0]);
    }

    dispatch({ action: TicketActions.Requester, isEntity: false, data: requester });
  }

  const handleTicketDataChange = (value, event) => {
    dispatch({ action: TicketActions.TeamId, isEntity: true, data: value.Id });
    dispatch({
      action: TicketActions.SelectedEnums,
      property: TicketActions.TeamId,
      data: value
    });
    dispatch({ action: TicketActions.SupportGroupId, isEntity: true, data: null });
    dispatch({ action: TicketActions.AssigneeId, isEntity: true, data: null });

    if (event === 'onChange') {
      if (value && value.Id && !value.TeamsAadObjectId) {
        setFilteredGroups([]);
        setFilteredUsers([]);
      } else {
        if (value && value.Id != null) {
          const filteredGroups = supportgroups.filter((x) => x.TeamsAadObjectId === value.TeamsAadObjectId);
          const group = (filteredGroups.length > 0) ? filteredGroups.find(x => x.TeamsChannelId == value.TeamsChannelId) : null;

          setFilteredGroups(filteredGroups);
          dispatch({ action: TicketActions.SupportGroupId, isEntity: true, data: group?.Id });
          const thisUsers = filterPlatformUsersInRole(supportgroups.find(s => s.Id == group?.Id)?.Members ?? teams.find((x) => x.Id === value.Id)?.Users ?? [], usersInRole);
          setFilteredUsers(thisUsers);
        } else {
          setFilteredGroups(supportgroups);
          setFilteredUsers(usersInRole);
        }
      }
      setAssigneeFullName('');
    }
  };

  function getDefaultTicketType(data, ticketTypes) {
    const defaultType = defaultTicketType(ticketTypes, (d, i) =>
      data.Entity.TicketTypeId !== undefined
        ? d.Id === data.Entity.TicketTypeId
        : d.IsDefault === true
    );

    return defaultType.Value;
  };
  
  const onChangeAssigneeQuery = useCallback((searchQuery: string) => {
    setAssigneeFullName(searchQuery);
  }, [data, assigneeFullName]);

  const customFormatterCallbackWrapper = useCallback((date) => customFormatter(date), [customFormatter]);

  const onChangeAffectedUsers = async (event: any) => {
    if (event.detail.length > 0) {
      let users = event.detail.map((item: any) => {
        const { id, displayName, givenName, surname, userPrincipalName, scoredEmailAddresses } = item;
        return {
          Name: displayName,
          GivenName: givenName,
          FamilyName: surname,
          Email: scoredEmailAddresses && scoredEmailAddresses[0]?.address,
          UserName: userPrincipalName ?? "",
          AadObjectId: id ?? null,
        };
      });
      
      const userList = [];
      for (let u of users) 
        userList.push(currentState.platformusers.find(p => p.UserName == u.UserName || (p.AadObjectId && u.AadObjectId && p.AadObjectId == u.AadObjectId)) ?? (await api.getOrCreatePlatformUser(u)));

      dispatch({ action: TicketActions.AffectedUsers, isEntity: true, data: userList });
    } else {
      dispatch({ action: TicketActions.AffectedUsers, isEntity: true, data: [] });
    }
  }

  const onChangeCollaborators = (_e: any, p: any) => dispatch({ action: TicketActions.Collaborators, isEntity: true, data: p.value.map(i => i.accessibilityitemprops) });

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        return (
          <div
            style={{
              height: '100%',
              ...ThemeColorScheme(globalTheme.siteVariables)
            }}
          >
            <div className={MgtCss.ticketForm}>
              <LabelElement label={t('ticket.add-ticket.request')} required={true}>
                <TextArea
                  maxLength={1000}
                  required
                  style={{ height: 60 }}
                  fluid
                  onChange={(e, p) => {
                    dispatch({ action: "Title", isEntity: true, data: p.value });
                  }}
                  value={data.Entity?.Title ? data.Entity.Title : ""}
                />
              </LabelElement>
              {actionType === ActionTypes.Create && (
                <LabelElement label={t('ticket.add-ticket.requester')} required={true}>
                <PeoplePicker
                  id='mgt-requester'
                  userType={UserType.user}
                  type={PersonType.person}
                  selectionMode="single"
                  selectionChanged={changeRequester}
                />
              </LabelElement>
              )}

              <CheckFeature featureName={LICENSE_FEATURE.AffectedUsers}>
              {actionType === ActionTypes.Create && (
                <LabelElement label={t('ticket.ticket-list.affected-users')}>
                  <PeoplePicker
                    id='mgt-affected-users'
                    userType={UserType.user} 
                    type={PersonType.person}
                    className={`width-med height-small`}
                    selectionChanged={onChangeAffectedUsers}
                  />
                </LabelElement>
              )}
              </CheckFeature>
              
              <NestedDropdown
                tabIndex={0}
                label={t('ticket.add-ticket.status')}
                items={ticketStatus.filter(item => item.Id != getStatusIdByGuid(ticketStatus, STATUS_GUID.DEFLECTED))
                  .map(item => ({
                    id: item.Id,
                    parentId: item.ParentId,
                    position: item.Position,
                    value: item.Value,
                    ...item
                  }))}
                dataItem={{
                  id: data.SelectedEnums.StatusId?.Id,
                  parentId: data.SelectedEnums.StatusId?.ParentId,
                  position: data.SelectedEnums.StatusId?.Position,
                  value: data.SelectedEnums.StatusId?.Value,
                  ...data.SelectedEnums.StatusId
                }}
                defaultValue={data.SelectedEnums.StatusId}
                onChange={value => {
                  dispatch({ action: TicketActions.StatusId, isEntity: true, data: value["Id"] });
                  dispatch({
                    action: TicketActions.SelectedEnums,
                    property: TicketActions.StatusId,
                    data: value
                  });

                }}
              />
              <CheckFeature featureName={LICENSE_FEATURE.TicketTypes}>
                <NestedDropdown
                  tabIndex={0}
                  label={t('ticket.add-ticket.type')}
                  items={ticketTypes.map(item => ({
                    id: item.Id,
                    parentId: item.ParentId,
                    position: item.Position,
                    value: item.Value,
                    ...item
                  }))}
                  dataItem={{
                    id: data.SelectedEnums.TicketTypeId?.Id,
                    parentId: data.SelectedEnums.TicketTypeId?.ParentId,
                    position: data.SelectedEnums.TicketTypeId?.Position,
                    value: data.SelectedEnums.TicketTypeId?.Value,
                    ...data.SelectedEnums.TicketTypeId
                  }}
                  defaultValue={data.SelectedEnums.TicketTypeId}
                  onChange={value => {
                    dispatch({ action: TicketActions.TicketTypeId, isEntity: true, data: value?.id });
                    dispatch({
                      action: TicketActions.SelectedEnums, 
                      property:TicketActions.TicketTypeId,
                      data: value
                    });
                }}
                  showClearBtn={true}
                />
              </CheckFeature>
              <NestedDropdown
                tabIndex={0}
                label={t('ticket.add-ticket.category')}
                items={categories.map(item => ({
                  id: item.Id,
                  parentId: item.ParentId,
                  position: item.Position,
                  value: item.Value,
                  ...item
                }))}
                dataItem={{
                  id: data.SelectedEnums.CategoryId?.Id,
                  parentId: data.SelectedEnums.CategoryId?.ParentId,
                  position: data.SelectedEnums.CategoryId?.Position,
                  value: data.SelectedEnums.CategoryId?.Value,
                  ...data.SelectedEnums.CategoryId
                }}
                defaultValue={data.SelectedEnums.CategoryId}
                onChange={value => {
                  dispatch({ action: TicketActions.CategoryId, isEntity: true, data: value["Id"] });
                  dispatch({
                    action: TicketActions.SelectedEnums,
                    property: TicketActions.CategoryId,
                    data: value
                  });
                }}
              />
              <NestedDropdown
                tabIndex={0}
                label={t('ticket.add-ticket.priority')}
                items={priority.map(item => ({
                  id: item.Id,
                  parentId: item.ParentId,
                  position: item.Position,
                  value: item.Value,
                  ...item
                }))}
                dataItem={{
                  id: data.SelectedEnums.PriorityId?.Id,
                  parentId: data.SelectedEnums.PriorityId?.ParentId,
                  position: data.SelectedEnums.PriorityId?.Position,
                  value: data.SelectedEnums.PriorityId?.Value,
                  ...data.SelectedEnums.PriorityId
                }}
                defaultValue={data.SelectedEnums.PriorityId}
                onChange={value => {

                  dispatch({ action: TicketActions.PriorityId, isEntity: true, data: value.id });
                  dispatch({
                    action: TicketActions.SelectedEnums,
                    property: TicketActions.PriorityId,
                    data: value
                  });
                }}
              />
              <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                <LabelElement label={t('ticket.add-ticket.team')} required={true}>
                <DropdownSupportGroup
                  items={teams}
                  handler={handleTicketDataChange}
                  properties={{ defaultValue: undefined }}
                  value={data.Entity.TeamId}
                  header={'FriendlyName'}
                  setSearchTeam={setSearchTeam}
                  searchQuery={searchTeam}
                  onSearchQueryChange={onChangeTeamQuery}
                />
                </LabelElement>
              </CheckFeature>
              <DropdownSupportGroup
                name={t('ticket.add-ticket.group')}
                items={filteredGroups}
                handler={(value: any, event?: string) => {
                  dispatch({ action: TicketActions.SupportGroupId, isEntity: true, data: value.Id });
                  dispatch({ action: TicketActions.AssigneeId, isEntity: true, data: null });
                  if (event == 'onChange') {
                    if (value.Id != null) setFilteredUsers(filterPlatformUsersInRole(value.Members, usersInRole));
                    else if (data.Entity.TeamId != null) setFilteredUsers(filterPlatformUsersInRole(teams?.find(x => x.Id == data.Entity.TeamId)?.Users || [], usersInRole));
                    else setFilteredUsers(usersInRole);
                    setAssigneeFullName('');
                  }
                }}
                properties={{ defaultValue: undefined }}
                value={data.Entity.SupportGroupId}
              />

              <DropdownAssignee
                setAssigneeFullName={setAssigneeFullName}
                searchQuery={assigneeFullName}
                onSearchQueryChange={onChangeAssigneeQuery}
                properties={{ defaultValue: undefined }}
                name={t('ticket.add-ticket.assignee')}
                assignees={filteredUsers.filter(o => !data.Entity?.Collaborators?.includes(o.Id))}
                handler={(value: any) => {
                  dispatch({ action: TicketActions.AssigneeId, isEntity: true, data: value });
                  if (!value) setAssigneeFullName('');
                }}
                value={data.Entity.AssigneeId}
              />

              <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
              {actionType === ActionTypes.Create && (
                <LabelElement label={t('ticket.ticket-list.collaborators')}>
                  <Dropdown
                    multiple
                    search
                    items={collaboratorsList.filter((t: any) => data.Entity.AssigneeId != t.accessibilityitemprops)}
                    value={collaboratorsList.filter(t => t.selected)}
                    fluid
                    a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                    onChange={onChangeCollaborators}
                    searchInput={{
                      inline: true,
                      inputRef: tagSearch,
                      styles: { paddingLeft: '8px', height: '32px' }
                    }}
                    style={{ backgroundColor: getBackgroundColor(globalTheme) }}
                    noResultsMessage = {<div>No User found</div>}
                    className={'collaborators--dropdown'}
                  />
                </LabelElement>
              )}
              </CheckFeature>

              <LabelElement label={t('ticket.add-ticket.due-date')}>
                <Datepicker
                  {...DatepickerLocalizations}
                  firstDayOfWeek={0}
                  formatMonthDayYear={customFormatterCallbackWrapper}
                  className={`${MgtCss.fluidDate} ${MgtCss.inverted}`}
                  onDateChange={(e, p) => {
                    dispatch({ action: TicketActions.DueDate, isEntity: true, data: new Date(p.value) });
                  }
                  }
                  {...(data.Entity.DueDate !== undefined && {
                    selectedDate: data.Entity.DueDate
                  })}
                />
              </LabelElement>
              <CheckFeature featureName={LICENSE_FEATURE.TicketTags}>
                <LabelElement label={t('ticket.add-ticket.tag')}>
                  {TicketTagPicker(globalTheme, "", tagSearch, false, isCreatingTag, tagList, TAGS.SELECT_TAGS, tagSearchQuery, addNewTag, selectTag, tagOnSearchQueryChange)}
                </LabelElement>
              </CheckFeature>
            </div>
          </div>
        );
      }}
    />
  );
};

const isEmpty = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const getRequester = async (requester: any) => {
  if (requester) return api.getOrCreatePlatformUser(requester);

  return {};
};

const getTeamByFilter = async (filter: string): Promise<any> => {
  let result = await api.getTeams(`?$filter=${filter}`);
  if (result.status === 200) {
    return result.data.value[0];
  }
  return null;
}

const getSupportGroupByFilter = async (filter: string): Promise<any> => {
  let result = await api.getSupportGroups(`?$filter=${filter}`);
  if (result.status === 200) {
    return result.data.value[0];
  }
  return null;
}

const addTicket = async (props: TicketEntityProps): Promise<any> => {
  let entity = props.Entity;
  let user: any = await getRequester(props.Requester);
  entity.RequesterId = user.Id;

  let source = await getPortalSource();
  entity.SourceId = source.Id;

  let result = await api.addTicket(entity);

  let ticket = result.data;
  ticket['Requester'] = user;
  if (result.status === 201) {
    return {
      requester: user,
      ticket: ticket
    };
  }

  return null;
};

const attachTagsToTicket = async (ticketId: number, tags: number[],
  t: any): Promise<any> => {
  let result = await api.udpateTicketTags(ticketId, tags.join(','));
  return (result.status === 201) ? { message: t('ticket.add-ticket.tags.tags-attached') } : null;
};

const attachCollaboratorsToTicket = async (ticketId: number, collaborators: any[]): Promise<any> => {
  let result = await api.UpdateCollaborators(ticketId, collaborators.map(a => a.PlatformUserId));
  return (result.status === 201) ? { message: 'Collaborators attached' } : null;
};

const attachAffectedUsersToTicket = async (ticketId: number, affectedUsers: any[]) => {
  let result = await api.UpdateAffectedUsers(ticketId, affectedUsers.map(a => a.PlatformUserId));
  return (result.status === 201) ? { message: 'Affected Users attached' } : null;
};

const updateTicket = async (ticketId: number, props: TicketEntityProps): Promise<number> => {
  let entity = props.Entity;
  entity.RequesterId = (await getRequester(props.Requester)).Id;

  let result = await api.updateTicket(ticketId, entity);
  return result.status;
};

const addTicketFromTaskModule = async (props: TicketEntityProps, onCloseTaskModule: any, t: any): Promise<any> => {
  let entity = props.Entity;
  entity.RequesterId = (await getRequester(props.Requester)).Id;
  const affectedUsers = entity?.AffectedUsers ?? [];
  const collaboratorsEmails = entity.Collaborators ?? [];
  delete entity.AffectedUsers;
  delete entity.Collaborators;

  api.addTicket(entity).then(async (result: any) => {
    await Promise.all([
      ((props.Entity?.Tags?.length ?? 0) > 0) ? UnitOfWork.AttachTagsToTicket(result.data.Id, props.Entity.Tags, t) : null,
      (affectedUsers.length > 0) ? UnitOfWork.AttachAffectedUsersToTicket(result.data.Id, affectedUsers.map(u => ({ PlatformUserId: u.Id }))) : null,
      (collaboratorsEmails) ? UnitOfWork.AttachCollaboratorsToTicket(result.data.Id, collaboratorsEmails.map(u => ({ PlatformUserId: u }))) : null,
      t
    ].filter(o => o !== null));
    
    let jsonCard = (await api.getCardPayload(result.data.Id, t('ticket.add-ticket.ticket-created'))).data.value || '';
    await api.sendToConversation({ JsonCard: jsonCard, IsShowTyping: true });
    onCloseTaskModule();
  })
  .catch(async () => {
    await api.sendToConversation({
      Error: t('ticket.add-ticket.ticket-created-error'),
      IsShowTyping: true
    });
    onCloseTaskModule();
  });

  return null;
};

export const UnitOfWork = {
  AddTicket: addTicket,
  UpdateTicket: updateTicket,
  AddTicketFromTaskModule: addTicketFromTaskModule,
  AttachTagsToTicket: attachTagsToTicket,
  AttachCollaboratorsToTicket: attachCollaboratorsToTicket,
  AttachAffectedUsersToTicket: attachAffectedUsersToTicket
};

export const LabelElement = (props: any) => {
  return (
    <div>
      <Flex>
        <Text content={props.label} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};

export const defaultItem = (items: any[], filter: any): any => {
  if (items == null || items == undefined || items.length === 0) return {};
  let result = items.filter(filter);
  if (result.length === 0) return items[0];
  return result[0];
};
export const defaultTicketType = (items: any[], filter: any) => {
  if (items == null || items == undefined || items.length === 0) return {};
  let result = items.filter(filter);
  if (result.length === 0) return {};

  return result[0];
}
const defaultDropdownItems = (props: any): any[] => {
  const { defaultItems, header, value } = props;
  return defaultItems.map((d: any) => {
    return { header: d[header], accessibilityItemProps: { value: d[value] }, selected: false, key: `dropdownItem-${d[value]}` };
  });
};

const defaultDropdownItem = (di: any): any => {
  if (di == null || di == undefined) return { id: 0, item: {} };
  return { header: di, selected: true };
};


export const DropdownAssignee = (props: any) => {
  const { setAssigneeFullName, name, assignees, value, handler, properties, searchQuery, onSearchQueryChange, isClearable = true } = props;
  const item = defaultItem(assignees, (d: any, _i: any) => value !== undefined ? d.Id === value : null);
  const defaultValue = Object.keys(item).length > 0 ? defaultDropdownItem(item.FullName) : null;
  const items = defaultDropdownItems({ defaultItems: assignees, header: 'FullName', value: 'Id' });
  let selectedItem = items.filter((i: any) => i.accessibilityItemProps.value == value);
  const setDefaultFromProps = properties &&
    properties.hasOwnProperty('defaultValue') &&
    properties['defaultValue'] == undefined ? false : true;
  if (handler && setDefaultFromProps) handler(item, 'init');
  let isError = false;
  if (properties && properties['error']) {
    isError = properties['error'];
    delete properties['error'];
  }

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        return (
          <div>
            {name && <div>{name}</div>}
            <Dropdown
              className={`${MgtCss.uiDropdown} cursor-pointer ${isError && 'border border-red-700'} ${AppCSS.Container}`}
              disabled={props.disabled || false}
              fluid
              search
              clearable={isClearable}
              items={items}
              value={selectedItem}
              searchQuery={searchQuery}
              onSearchQueryChange={(_e, data) => onSearchQueryChange(data.searchQuery)}
              {...(defaultValue && { defaultValue: defaultValue })}
              {...properties}
              onChange={(_e, p) => {
                if (handler) handler(p.value === null ? null : p.value['accessibilityItemProps'].value, 'onChange');
              }}
              noResultsMessage={
                <div style={{ textAlign: 'center' }}>
                  <Text content={`User not found`} />
                </div>
              }
              style={{ backgroundColor: getBackgroundColor(globalTheme, props.disabled) }}
            />
          </div>
        )
      }} />
  );
};

export const DropdownProperty = (props: DropdownProps) => {
  const { name, items, value, handler, properties } = props;
  const item = defaultItem(items, (d, i) =>
    value !== undefined ? d.Id === value : d.IsDefault === true
  );

  const defaultValue = defaultDropdownItem(item.Value);
  const setDefaultFromProps =
    properties &&
      properties.hasOwnProperty('defaultValue') &&
      properties['defaultValue'] == undefined
      ? false
      : true;
  if (handler && setDefaultFromProps) handler(item, 'init');
  return (
    <div>
      {name && <div>{name}</div>}
      <Dropdown
        fluid
        checkable
        items={items}
        defaultValue={defaultValue}
        {...properties}
        onChange={(e, p) => {
          if (handler)
            handler(
              p.value != null
                ? items.filter((v, i) => {
                  return v.Value === p.value['header'];
                })[0]
                : { Value: '' },
              'onChange'
            );
        }}
        className={MgtCss.uiDropdown}
      />
    </div>
  );
};

export const DropdownSupportGroup = (props: any) => {
  const { setSearchTeam, name, items, value, handler, properties, searchQuery, onSearchQueryChange, isClearable = true, header = 'Name' } = props;
  const { t } = useTranslation();  
  const item = defaultItem(items, (d: any, _i: any) => value !== undefined ? d.Id === value : null);
  const defaultValue = Object.keys(item).length > 0 ? defaultDropdownItem(item[header]) : null;
  const groups = defaultDropdownItems({ defaultItems: items, header: header, value: 'Id' });
  const selectedItem = groups.filter((group: any) => group.accessibilityItemProps.value == value);
  const setDefaultFromProps = properties &&
    properties.hasOwnProperty('defaultValue') &&
    properties['defaultValue'] == undefined ? false : true;
  if (handler && setDefaultFromProps && Object.keys(item).length > 0) handler(item, 'init');
  let isError = false;
  if (properties && properties['error']) {
    isError = properties['error'];
    delete properties['error'];
  }

  useEffect(() => {
    if (setSearchTeam)
      setSearchTeam(selectedItem?.[0]?.header ?? '');
  }, [setSearchTeam]);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        return (
          <div>
            {name && <div>{name}</div>}
            <Dropdown
              className={`${MgtCss.uiDropdown} cursor-pointer ${isError && 'border border-red-700'} ${AppCSS.Container} ${props.extraClasses}`}
              disabled={props.disabled || false}
              fluid
              {...(setSearchTeam && { search: true })}
              clearable={isClearable}
              value={selectedItem}
              items={groups}
              {...(searchQuery && { searchQuery: searchQuery })}
              {...(onSearchQueryChange && { onSearchQueryChange: (_e, data) => onSearchQueryChange(data.searchQuery)})}
              {...(defaultValue && { defaultValue: defaultValue })}
              {...properties}
              onChange={(_e: any, p: any) => {
                if (!p.value && setSearchTeam)
                  setSearchTeam("");
                
                if (handler) {
                  const selectedValue = p.value != null ? items.filter((v: any, _i: any) => v.Id === p.value['accessibilityItemProps'].value)[0] : { Id: null };
                  handler(selectedValue, 'onChange');
                }
              }}
              noResultsMessage={
                <div style={{ textAlign: 'center' }}>
                  <Text content={t('ticket.add-ticket.team-not-found')} />
                </div>
              }
              style={{ backgroundColor: getBackgroundColor(globalTheme, props.disabled) }}
              
            />
          </div>
        )
      }} />
  );
};

export default TicketForm;