import React, { createContext, useState, useContext } from 'react';
import { KBData, LoadingKB, SynonymData, ToUpdateKB } from './AppState';
import { PlatformUser } from './shared/interfaces/platformuser.interface';

export interface AppStateKBValue {
  knowledgeBase: KBData[];
  synonyms: SynonymData[];
  deletedKB: number[];
  editedKB: ToUpdateKB[];
  loadingKB: LoadingKB;
  tab: number;
  users: PlatformUser[];
} 
const defaultKBStateValue: AppStateKBValue = {
  knowledgeBase: [],
  synonyms: [],
  deletedKB: [],
  editedKB: [],
  loadingKB: {
    message: "",
    isLoading: true,
  },
  tab: 0,
  users: []
}

export const AppKBStateContext = createContext(defaultKBStateValue);

export const AppSetKBStateContext = createContext<
  React.Dispatch<React.SetStateAction<AppStateKBValue>> | undefined
>(undefined);

export const useSetKBState = () => {
  const setState = useContext(AppSetKBStateContext);
  if (!setState)
    throw new Error('useSetKBState was called outside of the AppSetKBStateContext provider');

  return setState;
};

interface Props {
  children: any;
}

const AppKBStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState(defaultKBStateValue);
  return (
    <AppKBStateContext.Provider value={state}>
      <AppSetKBStateContext.Provider value={setState}>
        {children}
      </AppSetKBStateContext.Provider>
    </AppKBStateContext.Provider>
  );
};

export default AppKBStateProvider;