import React, { useState } from 'react';
import { TicketDetailInfo } from '../../shared/components/TicketDetailInfo/TicketDetailInfo';
import { TicketDetailPopup } from '../../shared/components/TicketDetailPopup/TicketDetailPopup';
import AppCSS from './TicketDetailCard.module.css';

interface Props {
  ticketData: any;
  closePopup?: (value: boolean) => void;
  showViewMore?: boolean;
  idColorPurple?: boolean;
  attachmentsCount?: number;
  commentsCount?: number;
  isBoardMobileView?: boolean;
  detailCardApprovalsList?: TicketApprovals[];
  detailCardTaskList?: TicketTask[];
}

export const TicketDetailCard = ({
  ticketData,
  showViewMore,
  idColorPurple,
  attachmentsCount,
  commentsCount,
  isBoardMobileView,
  detailCardApprovalsList,
  detailCardTaskList
}: Props) => {
  const [isPopupShow, setIsPopupShow] = useState(false);

  const hideOrShowPopup = (value: boolean) => {
    setIsPopupShow(value);
  };

  return (
    <>
      <TicketDetailPopup
        attachmentsCount={attachmentsCount}
        commentsCount={commentsCount}
        isBoardMobileView={isBoardMobileView}
        ticketData={ticketData}
        open={isPopupShow}
        closePopup={hideOrShowPopup}
        detailCardApprovalsList={detailCardApprovalsList}
        detailCardTaskList={detailCardTaskList}
      />
      <div className={`hidden md:block pt-0.5 pb-1 px-0.5 overflow-auto ticket-card-detail`}>
        {/* For desktop */}
        <TicketDetailInfo 
          ticketData={ticketData} 
          attachmentsCount={attachmentsCount} 
          commentsCount={commentsCount} 
          isBoardMobileView={isBoardMobileView}
          detailCardApprovalsList={detailCardApprovalsList}
          detailCardTaskList={detailCardTaskList}
        />
      </div>
      <div className={`block md:hidden  pt-0.5 pb-1 px-0.5 overflow-auto ${showViewMore && AppCSS['ticket-detail-card-res']}`}>
        {/* For Mobile */}
        <TicketDetailInfo
          attachmentsCount={attachmentsCount}
          commentsCount={commentsCount}
          isBoardMobileView={isBoardMobileView}
          idColorPurple={idColorPurple}
          ticketData={ticketData}
          showViewMore={showViewMore}
          showDetailPopup={hideOrShowPopup}
          style={{ padding: '0.7rem 1.5rem' }}
        />
      </div>
    </>
  );
};
