import React, { useEffect, useState, useRef } from 'react';
import {
  ProviderConsumer,
  Flex,
  ProviderConsumer as FluentUIThemeConsumer,
  Dialog,
  CloseIcon
} from '@fluentui/react-northstar';
import * as monaco from 'monaco-editor';
import * as markdownit from 'markdown-it';
import * as ACDesigner from 'adaptivecards-designer';
import * as AdaptiveCards from 'adaptivecards';

// if you want to bundle the designer CSS using something like mini-css-loader:
import 'adaptivecards-designer/dist/adaptivecards-designer.css';

// Uncomment below if you choose to pass an empty hostContainers array
import 'adaptivecards-designer/dist/adaptivecards-defaulthost.css';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import { ITemplateFormProperties } from './TicketTemplates';
import { mergeStyleSets } from '@fluentui/react';
import { SetDefault_CustomForm } from './SetDefault_CustomForm';
import { CardDesigner } from 'adaptivecards-designer';
import { TEMPLATE } from '../shared/utils/constants';
import AppCSS from '../App.module.css';
import { themeNames } from '@fluentui/react-teams';
import { appState } from '../AppState';

export const blankPayloadData = {
  $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
  type: 'AdaptiveCard',
  version: '1.2',
  body: []
};

export const AdaptiveCardDesigner = (props: ITemplateFormProperties) => {
  const { templateData, dispatchData, headerContent } = props;
  const currentState = appState();
  const ref = useRef<HTMLDivElement>(null);
  const [tempData, setTempData] = useState<TicketTemplate>(
    Object.assign({}, templateData)
  );
  const [open, setOpen] = useState<boolean>(false);
  const [cardDesigner, setCardDesigner] = useState<CardDesigner>(undefined);
  
  const isDesigningInLightMode = () => {
    let label = document.querySelector('.ms-dropdown-label');
    if (label?.innerHTML === 'Microsoft Teams - Light') {
      return true;
    }
    return false;
  }

  const classNames = mergeStyleSets({
    acdDesignerRoot: {
      overflow: 'auto !important',
      maxHeight: '100%',
      display: 'block',
      '.content': {
        maxHeight: '88%',
        minHeight: '88%',
        border: 'thin solid lightgrey'
      },

      '.acd-designer-host, .cardHost':{
        marginTop: '8px !important'
      },
      
      '.acd-toolbar-button': {
        ':hover, :active, :focus': {
          background: 'rgb(61,61,61) !important'
        }
      },

      '.default-ac-textBlock, .acd-toolbar-label': {
        color: 'white !important '
      },

      '.acd-propertySheet-pane, .acd-designer-cardArea, .acd-previewRightAndBottomDocks, .acd-treeView-pane, .acd-toolPalette-pane, .monaco-editor-background, .margin-view-overlays, .acd-treeView-host': {
        color: 'white',
        background: 'rgb(41,41,41) !important'
      },

      '.acd-palette-category, .default-ac-container, .acd-toolbar':{
        background: 'rgb(51,51,51)',
        color: 'white'
      },
      
      '.default-ac-container div': {
        background: 'rgb(51,51,51)',
        color: 'white'
      },

      '.acd-palette-item': {
        ':hover, :active, :focus': {
          background: 'rgb(51,51,51) !important'
        }
      },

      '.acd-tree-item': {
        color: 'white',
        background: 'rgb(51,51,51)',
        ':hover, :active, :focus': {
          background: 'rgb(61,61,61) !important'
        }
      },

      '.acd-toolbox-header-title': {
        color: 'white'
      },

      '.ms-ctrl': {
        background: 'rgb(71,71,71) !important'
      },
      '.ms-dropdown-label, .ac-textBlock, .ac-textRun, .mtk1': {
        color: 'white'
      },

      '.ms-ctrl-dropdown': {
        background: 'rgb(71,71,71) !important',
        color: 'white !important',
        ':hover, :active, :focus': {
          background: 'rgb(61,61,61) !important'
        }
      },

      '.default-ac-multichoiceInput, .default-ac-textInput, .default-ac-numberInput': {
        background: 'rgb(91,91,91) !important',
        color: 'white'
      },

      '.default-ac-container.default-ac-adaptiveCard':{
        background: 'rgb(51,51,51) !important'
      },

      '.ac-input.ac-textInput, .ac-input.ac-dateInput, .ac-input.ac-timeInput, .ac-input.ac-numberInput, .ac-input.ac-multichoiceInput.ac-choiceSetInput-compact':{
        background:`${!isDesigningInLightMode() ? 'rgb(51,51,51)':' '}`,
        color:`${!isDesigningInLightMode() ? 'white':' '}`
      }
    },

  });
  const classNamesLightMode = mergeStyleSets({
    acdDesignerRoot: {
      overflow: 'auto !important',
      maxHeight: '100%',
      display: 'block',
      '.content': {
        maxHeight: '88%',
        minHeight: '88%',
        border: 'thin solid lightgrey'
      },

      '.acd-designer-host, .cardHost':{
        marginTop: '8px !important'
      },
      
    },

  });
  const setDesigner = element => {
    ACDesigner.CardDesigner.onProcessMarkdown = (text, result) => {
      result.outputHtml = new markdownit().render(text);
      result.didProcess = true;
    };

    AdaptiveCards.GlobalRegistry.elements.unregister('Media');
    ACDesigner.CardDesignerSurface.cardElementPeerRegistry.unregisterPeer(
      AdaptiveCards.Media
    );

    let hostContainers: ACDesigner.HostContainer[] = [
      new ACDesigner.LightTeamsContainer(
        'Microsoft Teams - Light',
        'containers/teams-container.css'
      ),
      new ACDesigner.DarkTeamsContainer(
        'Microsoft Teams - Dark',
        'containers/teams-container.css'
      )
    ];

    let designer = new ACDesigner.CardDesigner(hostContainers);
    /* Modify the toolbar */
    let myButton = new ACDesigner.ToolbarButton(
      'myButton',
      TEMPLATE.DESIGNER_CUSTOM_BUTTON,
      'acd-icon-newCard',
      sender => {
        setOpen(true);
      }
    );

    designer.toolbar.insertElementBefore(
      myButton,
      ACDesigner.CardDesigner.ToolbarCommands.HostAppPicker
    );

    designer.attachTo(element);
    designer.monacoModuleLoaded(monaco);

    designer.toolbar['_elements'].map(x => {
      const hiddenToolbars = [
        ACDesigner.CardDesigner.ToolbarCommands.NewCard,
        ACDesigner.CardDesigner.ToolbarCommands.CopyJSON,
        ACDesigner.CardDesigner.ToolbarCommands.Help
      ];
      if (hiddenToolbars.includes(x.id)) x['_renderedElement']['hidden'] = true;
      return x;
    });

    designer.targetVersion = AdaptiveCards.Versions.v1_4;
    designer.jsonEditorToolbox.collapse();
    
    if (
      templateData.CardResponseJson &&
      templateData.CardResponseJson.length > 0
    )
      designer.setCard(JSON.parse(templateData.CardResponseJson));
    else designer.setCard(blankPayloadData);

    designer.onCardValidated = (
      cd: ACDesigner.CardDesigner,
      validationLogEntries: AdaptiveCards.IValidationEvent[]
    ) => {
      templateData.CardResponseJson = JSON.stringify(cd.getBoundCard());
      dispatchData(templateData);
    };

    designer.onCardPayloadChanged = (cd: ACDesigner.CardDesigner) => {
      const card = cd.getBoundCard();
      const inputs = parseInputs(card['body']);
      removeTargetVersionWarning();
      const containsSpaceOrStartsWithNumber = inputs?.some(idText => /[^a-zA-Z\d]|^\d/.test(idText));
      if (containsSpaceOrStartsWithNumber) addInputIdSpaceValidation();
      const errorPane = document.getElementById('errorPane');
      templateData.isCardValid = !errorPane.hasChildNodes();
      dispatchData(templateData);
    };

    return designer;
  };

  const removeUnwantedActions = (selector: string) => {
    const elements = document.querySelectorAll<HTMLElement>(selector);
    Array.from(elements).forEach((element, i) => {
      const textProp = 'textContent' in document ? 'textContent' : 'innerText';
      if (
        !['Action.OpenUrl', 'Action.ToggleVisibility']?.some(
          x => x == element[textProp]
        )
      ) {
        element.remove();
      }
    });
  };

  const removeTargetVersionWarning = () => {
    const errorPane = document.getElementById('errorPane');
    const elements = document.querySelectorAll<HTMLElement>(
      '.acd-error-pane-message'
    );
    Array.from(elements).forEach((element, i) => {
      const textProp = 'textContent' in document ? 'textContent' : 'innerText';
      if (element[textProp].toLowerCase().includes('target version')) {
        element.remove();
      }
    });

    errorPane.style.display = errorPane.children.length == 0 ? 'none' : 'block';

    const botName = document.querySelector<HTMLElement>(
      '.teams-botNameAndTime'
    );
    if(botName){
      botName.innerText = "CARD DESIGNER";
      botName.style.color = currentState?.theme === themeNames.Dark ? 'white': 'black';
      botName.style.fontSize = '12px';
    }
  };

  const addInputIdSpaceValidation = () => {
    const errorPane = document.getElementById('errorPane');
    if (errorPane) {
      const newDiv = document.createElement('div');
      newDiv.className = 'acd-error-pane-message selectable';
      const newContent = document.createTextNode(
        `[Validation] Input Ids must not contain spaces or special characters or start with numbers.`
      );
      newDiv.appendChild(newContent);
      errorPane.appendChild(newDiv);
      errorPane.style.display = 'block';
    }
  };

  const parseInputs = (container: any[]) => {
    let inputs = [];
    if (container && container.length > 0) {
      container.forEach(element => {
        const type: string = element['type'];
        if (type == 'Container' && element['items'])
          inputs = inputs.concat(parseInputs(element['items']));
        else if (type == 'ColumnSet' && element['columns']) {
          element['columns'].forEach(col => {
            if (col['items']) inputs = inputs.concat(parseInputs(col['items']));
          });
        } else if (type.startsWith('Input.') && element['id'])
          inputs.push(element['id']);
      });
    }
    return inputs;
  };

  useEffect(() => {
    if (ref.current) {
      let designer = setDesigner(ref.current);
      setCardDesigner(designer);
      removeTargetVersionWarning();
    }
  }, [ref]);

  useEffect(() => {
    if (!open && cardDesigner) {
      if (
        templateData.CardResponseJson &&
        templateData.CardResponseJson.length > 0
      )
        cardDesigner.setCard(JSON.parse(templateData.CardResponseJson));
      else cardDesigner.setCard(blankPayloadData);
    }
  }, [open]);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        return (
          <>
            <Dialog
              className={AppCSS.confirmDialog}
              content={
                <SetDefault_CustomForm
                  templateProps={{
                    headerContent: headerContent,
                    templateData: tempData
                  }}
                  isUpdate
                />
              }
              open={open}
              cancelButton="Cancel"
              confirmButton="Confirm"
              onCancel={() => {
                setOpen(false);
              }}
              onConfirm={() => {
                templateData.CardResponseJson = tempData.CardResponseJson;
                setOpen(false);
              }}
            />
            <div
              className={`${globalTheme.siteVariables.theme === themeNames.Dark ? classNames.acdDesignerRoot : classNamesLightMode.acdDesignerRoot}`}
              ref={ref}
              onClick={evt => {
                if (evt.target['className'].includes('acd-peerButton-text'))
                  removeUnwantedActions('.ms-ctrl-dropdown-item');
                if (
                  evt.target['className'].includes(
                    'default-ac-multichoiceInput'
                  )
                )
                  removeUnwantedActions('[value*="Action."]');

                const element = document.querySelector<HTMLElement>(
                  '.default-ac-pushButton[title="Add an action"]'
                );
                if (element) {
                  element.addEventListener('click', () => {
                    removeUnwantedActions('.ms-ctrl-dropdown-item');
                  });
                }
              }}
            ></div>
          </>
        );
      }}
    />
  );
};
