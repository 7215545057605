import React, { useEffect, useState } from 'react';
import { RelateTicket } from '../RelateTicketDialog';
import { TicketListCard } from '../TicketListCard/TicketListCard';
import { RelationType } from '../RelateTicketHelper';
import { Dropdown, Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import { threedigitized } from '../../../../tikit/ticketHelper';
import { appState } from '../../../../AppState';
import { EmptyData } from '../../EmptyData';
import EmptySVG from '../../../../../svg/empty.svg';
import FlashlightSVG from '../../../../../svg/flashlight.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  relatedTicketData: RelateTicket;
  setRelatedTicketData?: (relateTicketData: RelateTicket) => void;
  tickets?: Ticket[];
}

interface DropDownItem {
  key: number,
  header: string
}

export const TicketsSelection = ({ relatedTicketData, setRelatedTicketData, tickets }: Props) => {
  const {t} = useTranslation();
  const currentState = appState();
  const [filteredTickets, setFilteredTickets] = useState<Ticket[]>([...tickets]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [statuses, setStatuses] = useState<DropDownItem[]>([]);
  const [statusId, setStatusId] = useState<number>(0);
  
  useEffect(() => {
    const statusList: DropDownItem[] = currentState.ticketStatus.map(s => ({ key: s.Id, header: s.Value}));
    const defaultItem: DropDownItem = { key: 0, header: t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.all-statuses')};
    setStatuses([defaultItem, ...statusList]);
  }, []);
  
  const filterTickets = (key: string, selectedStatusId: number = 0) => {
    const searchText = key?.toLowerCase();
    const ticketsList = tickets.filter((t: Ticket) => (selectedStatusId === 0 || t.StatusId === selectedStatusId) && 
    (!searchText || threedigitized(t.Id).toString().includes(searchText) || t.Title?.toLowerCase().includes(searchText.toLowerCase())));
    setFilteredTickets(ticketsList);
  };
  
  const onStatusChange = (selectedStatusId: number) => {
    filterTickets(searchKey, selectedStatusId);
    setStatusId(selectedStatusId);
  }
  
  const onSearchFieldChange = (value: string) => {
    filterTickets(value, statusId);
    setSearchKey(value);
  }
  
  const getHeader = () => {
    switch (relatedTicketData.relationShipType) {
      case RelationType.MergeTickets:
        return `${t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.select-ticket')} #${threedigitized(
          relatedTicketData.sourceTicketId
        )}`;
      case RelationType.RelateTickets:
        return t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-select-ticket');
      case RelationType.ParentChild:
        return t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.make-child');
      case RelationType.BlockedBy:
        return t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.blocking-ticket');
      default:
        return t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.select-tickets');
    }
  }
  
  const getDisplayMessage = () => {
    switch (relatedTicketData.relationShipType) {
      case RelationType.MergeTickets:
        return t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.no-requestor-ticket');
      case RelationType.RelateTickets:
        return t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.no-available-ticket');
      default:
        return t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.no-ticket');
    }
  }
  
  const onTicketClick = (ticketId: number) => {
      let relatedTicketIds = [...relatedTicketData.relatedTicketIds];
      if (!relatedTicketIds.includes(ticketId)) relatedTicketIds.push(ticketId);
      else relatedTicketIds.splice(relatedTicketIds.indexOf(ticketId), 1);
      setRelatedTicketData({...relatedTicketData , relatedTicketIds});
  }
  
  const getEmptyIcon = () => {
    return <>
      <Flex className='relate-empty-icon'>
        {searchKey ?
        <EmptyData
          SVGIcon={ <FlashlightSVG width={77} height={77} className={'mb-4'} />}
          headerText={t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.empty-header')}
          subheaderText={t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.empty-sub-header')}
        /> :
        <EmptyData
          headerText={getDisplayMessage()}
          SVGIcon={<EmptySVG width={75} height={75} />}
        />}
      </Flex>
    </>;
  }
  
  return (
    <>
      <div className='dialog-header font-bold'>{getHeader()}</div>
      <div className='dialog-filter'>
        {tickets.length > 0 && statuses.length > 0 && <>
          <div className={`inline-block mr-2 
          ${relatedTicketData.relationShipType === RelationType.MergeTickets ? 'search-merge' : 'search-relate'}`}>
            <Input fluid icon={<SearchIcon />} defaultValue={searchKey} 
              clearable placeholder={t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.search-placeholder')}
              onChange={(_: any, event: { value: string; }) => onSearchFieldChange(event.value)}
            />
          </div>
          {relatedTicketData.relationShipType !== RelationType.MergeTickets &&
          (<div className='inline-block status-filter'>
            <Dropdown className='cursor-pointer'
              fluid items={statuses} placeholder={t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.select-status')}
              onChange={(_: any, event: any) => onStatusChange(event.value["key"])}
              triggerButton={
                <span className="font-semibold">
                  {statuses.filter(s => s.key === statusId)[0]?.header}
                </span>
            }/>
          </div>)}
        </>}
      </div>
      <div className='dialog-body-tickets overflow-auto break-all'>
        {filteredTickets.length > 0 ?
          (filteredTickets.map((ticket: Ticket) => (
            <TicketListCard
              ticket={ticket}
              isSelected={relatedTicketData.relatedTicketIds.includes(ticket.Id)}
              onClick={() => onTicketClick(ticket.Id)}
            />
          ))) : getEmptyIcon()}
      </div>
    </>
  );
};
